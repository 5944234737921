import React from "react";
import { Grid, TableCell, TableRow } from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { ButtonComponent } from "../../components/form_components/button";
import { TableComponent } from "../../components/table_component";
import { AddModalComponent } from "../../components/modal_component";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { HFPagination } from "../../components/pagination";
import {
  tableHeadCell,
  moduleHeadContainer,
  tableParentHolder,
} from "../../styles/common_styles";
import ViewIcon from "../../assets/icons/view.svg";
import { EditModalComponent } from "../../components/edit_modal_component";
import { TableFilter } from "../../components/table_filter";
import { BlockOrDeleteModalComponent } from "../../components/modal_component";
import "../../styles/common_styles.css";
import { Loader } from "../../components/loader_component";
import { ConvertDateString } from "../../utils/helpers";
import {
  TableCellComponent,
  IconTableCellComponent,
} from "../../components/table_cell_component";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import BlockIcon from "../../assets/icons/block.svg";
import UnBlockIcon from "../../assets/icons/unblock.svg";
import { CommonServices } from "../../services/common_services";
import { TroubleshootOutlined } from "@mui/icons-material";
import { RoleDetailModal } from "../../components/roleDetailModal";

const GlobalOperations = () => {
  const [setCountries] = React.useState([]);
  const [selectedCountry] = React.useState(null);
  const [selectedState] = React.useState(null);
  const [selectedCity] = React.useState(null);
  const [loaderData, setLoaderData] = React.useState(true);
  const [addModal, setAddModal] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [glblOperation, setGlblOperations] = React.useState([]);
  const [searchData, setSearchData] = React.useState("");
  const [actionId, setActionId] = React.useState("");
  const [roleModal, setRoleModal] = React.useState(false);
  const [roleuserId, setRoleuserId] = React.useState("");

  const addModalHandleOpen = () => setAddModal(true);
  const addModalCloseModal = () => {
    setAddModal((prevState) => !prevState);
    getGlobalOperationsDetails();
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const editModalHandleOpen = (id) => {
    setEditModal(true);
    setEditId(id);
  };

  const editModalCloseModal = () => {
    setEditModal(false);
    setEditId("");
    getGlobalOperationsDetails();
  };

  const [filterState, setFilterState] = React.useState({
    blocked: false,
    unBloced: false,
    edited: false,
    deleted: false,
    isPending: null,
  });

  const [actionType, setActiontype] = React.useState({
    title: "",
    type: null,
  });

  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const [deleteModal, setDeleteModal] = React.useState(false);

  const DeleteModalClose = () => setDeleteModal(false);

  const FilterChangingValue = (selectedValue) => {
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
    setFilterState(selectedValue);
  };

  const blockOrDeleteHandleOpen = (id, title, type) => {
    setActionId(id);
    setDeleteModal(true);
    setActiontype({ ...actionType, title, type });
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleRes = async (data) => {
    try {
      //handles No condition
      if (data === 1) {
        DeleteModalClose();
      } else {
        //handles block condition
        if (actionType.type === 1) {
          const blockedUser = await ManagementServices.blockAdmin(actionId);
          if (blockedUser.status) {
            DeleteModalClose();
            setAlrtMsg(blockedUser.message);
            setSuccessAlrtOpen(true);
            getGlobalOperationsDetails();
            DeleteModalClose();
          } else {
            setAlrtMsg(blockedUser.message);
            setErrorAlrtOpen(true);
          }
        } else if (actionType.type === 3) {
          const unblockedConsumer = await ManagementServices.unblockAdmin(
            actionId
          );
          if (unblockedConsumer.status === 1) {
            setAlrtMsg(unblockedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getGlobalOperationsDetails();
          } else {
            setAlrtMsg(unblockedConsumer.message);
            setErrorAlrtOpen(true);
          }
        }
        //handles delete condition
        else {
          const deletedUser = await ManagementServices.deleteAdmin(actionId);
          if (deletedUser.status) {
            setAlrtMsg(deletedUser.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getGlobalOperationsDetails();
          } else {
            setAlrtMsg(deletedUser.message);
            setErrorAlrtOpen(true);
          }
        }
      }
    } catch (error) {
      console.log(`Error occured :${error}`);
    }
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const getGlobalOperationsDetails = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        country: selectedCountry,
        ...filterState,
        state: selectedState,
        city: selectedCity,
        searchValue: searchData,
      };
      const opDetails = await ManagementServices.getRoleWiseUserDetails(
        payload
      );

      if (opDetails.status === 1) {
        const {
          data: { currentPage, pageSize, totalPages, totalUsers, userDetails },
        } = opDetails;

        setPaginationData({
          ...paginationData,
          currentPage: currentPage,
          RecordsForPage: pageSize,
          totalRecords: totalUsers,
          pageCount: totalPages,
        });
        setGlblOperations(userDetails);
        setLoaderData(false);
        // setAlrtMsg(opDetails.message);
        // setSuccessAlrtOpen(true)
      } else {
        setAlrtMsg(opDetails.message);
        setErrorAlrtOpen(true);
        setLoaderData(false);
      }
    } catch (error) {
      console.error(`Error occurred: ${error}`);
      setLoaderData(false);
    }
  };

  const roleModalOpen = (id) => {
    console.log(id, "iddddd");
    setRoleModal(true);
    setRoleuserId(id);
  };
  const roleCloseModal = () => setRoleModal(false);

  const TableHeader = [
    "S No",
    "User Id",
    "First Name",
    "Last Name",
    "Phone",
    "Email Id",
    "Date",
    "Last Modified Date",
    "Address",
    "Actions",
  ];

  const iconConfigurations = (data) => [
    {
      condition: true,
      icon: EditIcon,
      alt: "Edit",
      onClick: () => editModalHandleOpen(data.id),
    },
    {
      condition: data.block_status === 1,
      icon: UnBlockIcon,
      alt: "Unblock",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
    },
    {
      condition: data.block_status === 0,
      icon: BlockIcon,
      alt: "Block",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
    },
    {
      condition: true,
      icon: DeleteIcon,
      alt: "Delete",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
    },
    {
      condition: true,
      icon: ViewIcon,
      alt: "View",
      onClick: () => roleModalOpen(data.id),
    },
  ];

  // const iconConfigurations = (data) => [
  //   {
  //     condition: true,
  //     icon: EditIcon,
  //     alt: "Edit",
  //     onClick: () => editModalHandleOpen(data.id),
  //   },
  //   {
  //     condition: data.block_status === 1,
  //     icon: UnBlockIcon,
  //     alt: "Unblock",
  //     onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
  //   },
  //   {
  //     condition: data.block_status === 0,
  //     icon: BlockIcon,
  //     alt: "Block",
  //     onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
  //   },
  //   {
  //     condition: true,
  //     icon: DeleteIcon,
  //     alt: "Delete",
  //     onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
  //   },
  //   {
  //     condition: true,
  //     icon: ViewIcon,
  //     alt: "View",
  //     onClick: () => roleModalOpen(data.id),
  //   },
  // ];
  const TableHeaderRow = TableHeader.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = glblOperation.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCellComponent
          value={
            (paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index
          }
          indexColoumn={true}
        />
        <TableCellComponent value={data.user_id} />
        <TableCellComponent value={data.first_name} />
        <TableCellComponent value={data.last_name} />
        <TableCellComponent value={data.phone} />
        <TableCellComponent value={data.email} />
        <TableCellComponent value={ConvertDateString(data.created_at)} />
        <TableCellComponent
          value={data.updated_at ? ConvertDateString(data.updated_at) : "-"}
        />
        <TableCellComponent value={data.address} />
        <IconTableCellComponent
          index={index}
          data={data}
          iconConfigurations={iconConfigurations}
        />
      </TableRow>
    );
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  React.useEffect(() => {
    getGlobalOperationsDetails();
    GetCountries();
  }, []);

  React.useEffect(() => {
    getGlobalOperationsDetails();
    GetCountries();
  }, [pageConfig.min, searchData, filterState]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={moduleHeadContainer}
          >
            <Grid item md={4}>
              <TitleComponent title={"Global Ops"} />
            </Grid>
            <Grid item md={8}>
              <Grid container gap={1} justifyContent={"end"}>
                <Grid item md={2.5}>
                  <TableFilter filterChange={FilterChangingValue} />
                </Grid>
                <Grid item md={3}>
                  <SearchFieldComponent searchText={handleSearchData} />
                </Grid>
                <Grid item md={1.5}>
                  <ButtonComponent
                    onClick={addModalHandleOpen}
                    btnText={"ADD"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={tableParentHolder}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <AddModalComponent
            openhandler={addModal}
            handleClose={addModalCloseModal}
            title="Add"
            trigger={getGlobalOperationsDetails}
          />
          <EditModalComponent
            open={editModal}
            handleClose={editModalCloseModal}
            edit={editId}
          />
          <RoleDetailModal
            open={roleModal}
            handleClose={roleCloseModal}
            userId={roleuserId}
          />
          <BlockOrDeleteModalComponent
            deleteopen={deleteModal}
            resType={handleRes}
            onClose={DeleteModalClose}
            title={`Do you want to ${actionType.title}?`}
          />
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};

export default GlobalOperations;

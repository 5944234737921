import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "../styles/common_styles.css";
import carousel_image1 from "../assets/images/carousel.png";
import carousel_image3 from "../assets/images/carousel3.png";
import carousel_image4 from "../assets/images/carousel4.jpg";

export default () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    mode: "free",
    slides: { origin: "center", perView: 1.4, spacing: 15 },
    created(s) {
      setCurrentSlide(s.track.details.rel);
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
  });

  useEffect(() => {
    if (!slider) return;

    const interval = setInterval(() => {
      slider.current.next();
    }, 3000);

    return () => clearInterval(interval);
  }, [slider]);

  return (
    <div style={{ position: "relative" }}>
      <div ref={sliderRef} className="keen-slider">
        <div
          className="keen-slider__slide number-slide1"
          style={{ backgroundImage: `url(${carousel_image1})`, width: "90%" }}
        >
          <div
            style={{
              position: "absolute",
              top: "4%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "black",
              fontSize: "50px",
              textAlign: "start",
              fontWeight: "850",
            }}
          >
            Consumer
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "50px",
              left: "35%",
              transform: "translateX(-35%)",
              color: "#fff",
              fontSize: "18px",
              textAlign: "end",
              width: "82%",
            }}
          >
            Hungry?? Tired of cooking...cleaning and repeat ?? Is planning every
            single meal an ordeal ? Find delicious food right around you. Don’t
            just eat what you like.Everybody does that.Here is something
            new...Eat What you like and at price that you like too.Place a food
            bid request and let chefs around you please you with their price
          </div>
        </div>

        <div
          className="keen-slider__slide number-slide2"
          style={{ backgroundImage: `url(${carousel_image1})`, width: "80%" }}
        >
          <div
            style={{
              position: "absolute",
              top: "4%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "black",
              fontSize: "50px",
              textAlign: "start",
              fontWeight: "850",
            }}
          >
            Chef
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "50px",
              left: "35%",
              transform: "translateX(-35%)",
              color: "#fff",
              fontSize: "18px",
              textAlign: "end",
              width: "82%",
            }}
          >
            Hungry?? Tired of cooking...cleaning and repeat ?? Is planning every
            single meal an ordeal ? Find delicious food right around you. Don’t
            just eat what you like.Everybody does that.Here is something
            new...Eat What you like and at price that you like too.Place a food
            bid request and let chefs around you please you with their price
          </div>
        </div>

        <div
          className="keen-slider__slide number-slide3"
          style={{ backgroundImage: `url(${carousel_image1})`, width: "80%" }}
        >
          <div
            style={{
              position: "absolute",
              top: "4%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "black",
              fontSize: "50px",
              textAlign: "start",
              fontWeight: "850",
            }}
          >
            Chef
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "50px",
              left: "35%",
              transform: "translateX(-35%)",
              color: "#fff",
              fontSize: "18px",
              textAlign: "end",
              width: "82%",
            }}
          >
            Hungry?? Tired of cooking...cleaning and repeat ?? Is planning every
            single meal an ordeal ? Find delicious food right around you. Don’t
            just eat what you like.Everybody does that.Here is something
            new...Eat What you like and at price that you like too.Place a food
            bid request and let chefs around you please you with their price
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "10px",
          width: "100%",
        }}
      >
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            onClick={() => slider.current.moveToSlideRelative(index)}
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: currentSlide === index ? "black" : "gray",
              margin: "0 5px",
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

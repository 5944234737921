import * as React from "react";
import { Grid, TableCell, TableRow, TextField } from "@mui/material";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import "../../styles/common_styles.css";
import { ButtonComponent } from "../../components/form_components/button";
import { CommunicationModalComponent } from "../../components/consumer_modal";
import { useLocation } from "react-router-dom";
import { TableComponent } from "../../components/table_component";
import { tableHeadCell } from "../../styles/common_styles";
import { ManagementServices } from "../../services/management_services";
import { TableCellComponent } from "../../components/table_cell_component";
import { HFPagination } from "../../components/pagination";
import { Loader } from "../../components/loader_component";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";

export const Consumercommunication = () => {
  const location = useLocation();
  const moduleType = location.state?.module;
  const userId = location.state?.userId;
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [addOrEditModal, setAddOrEditModal] = React.useState(false);
  const addOrEditModalHandleOpen = () => setAddOrEditModal(true);
  const addOrEditModalCloseModal = () => setAddOrEditModal(false);
  const datePickerRef = React.useRef(null);
  const [loaderData, setLoaderData] = React.useState(true);
  const [consumerCommunication, setConsumerCommunication] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [searchData, setSearchData] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const getUserDetails = async () => {
    try {
      if (moduleType === "1" || moduleType === 1) {
        const details = await ManagementServices.consumerAboutDetails(userId);
        if (details.status === 1) {
          setUserDetails(details.data.consumerDetails);
        } else {
          setAlrtMsg(details.message);
          setErrorAlrtOpen(true);
        }
        return;
      }
      const details = await ManagementServices.chefAboutDetails(userId);
      if (details.status === 1) {
        setUserDetails(details.data.chefDetails);
      } else {
        setAlrtMsg(details.message);
        setErrorAlrtOpen(true);
      }
      return;
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const getCommunicationDetails = async () => {
    // const formattedDate = startDate.toISOString().split("T")[0];
    try {
      let ConsumerDetails;
      let ChefDetails;
      if (moduleType === 1 || moduleType === "1") {
        const payload = {
          userId: userId,
          type: 1,
          min: pageConfig.min,
          max: pageConfig.max,
          fromdate: startDate,
          todate: endDate,
          search: searchData,
        };
        ConsumerDetails = await ManagementServices.CommunicationDetails(
          payload
        );
        if (ConsumerDetails.status === 1) {
          const {
            data: {
              currentPage,
              pageSize,
              totalPages,
              totalCommunications,
              communications,
            },
          } = ConsumerDetails;
          setPaginationData({
            ...paginationData,
            currentPage: currentPage,
            RecordsForPage: pageSize,
            totalRecords: totalCommunications,
            pageCount: totalPages,
          });
          setConsumerCommunication(communications);
          // setAlrtMsg(ConsumerDetails.message)
          // setSuccessAlrtOpen(true)
          setLoaderData(false);
        } else {
          setAlrtMsg(ConsumerDetails.message);
          setErrorAlrtOpen(true);
        }
      } else {
        const payload = {
          userId: userId,
          type: 2,
          min: pageConfig.min,
          max: pageConfig.max,
          fromdate: startDate,
          todate: endDate,
          search: searchData,
        };
        ChefDetails = await ManagementServices.CommunicationDetails(payload);
        if (ChefDetails.status === 1) {
          const {
            data: {
              currentPage,
              pageSize,
              totalPages,
              totalCommunications,
              communications,
            },
          } = ChefDetails;
          setPaginationData({
            ...paginationData,
            currentPage: currentPage,
            RecordsForPage: pageSize,
            totalRecords: totalCommunications,
            pageCount: totalPages,
          });
          setConsumerCommunication(communications);
          // setAlrtMsg(ChefDetails.message);
          // setSuccessAlrtOpen(true);
          setLoaderData(false);
        } else {
          setAlrtMsg(ChefDetails.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  let tableHeaders;

  if (moduleType === 1 || moduleType === "1") {
    tableHeaders = ["Sno", "Date", "Name", "Email", "Message"];
  } else {
    tableHeaders = ["Sno", "Date", "Name", "Email", "Message"];
  }

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = consumerCommunication?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCell sx={{ ml: 10, textAlign: "center" }}>
          {(paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>{data.created_at}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{data.name}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{data.email}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{data.message}</TableCell>
      </TableRow>
    );
  });

  React.useEffect(() => {
    getCommunicationDetails();
    getUserDetails();
  }, [pageConfig.min, searchData, startDate, endDate]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            spacing={2}
            justifyContent={"end"}
            alignItems={"end"}
            mb={2}
          >
            <Grid item md={3}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  getCommunicationDetails();
                }}
              />
            </Grid>
            <Grid sx={{ mb: 1.5, fontWeight: "bold", ml: 2.5 }}>To</Grid>
            <Grid item md={1.5}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "endDate",
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  getCommunicationDetails();
                }}
              />
            </Grid>
            <Grid item md={1.5}>
              <ButtonComponent
                btnText={"ADD"}
                onClick={addOrEditModalHandleOpen}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 5 }}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <Grid>
            <CommunicationModalComponent
              open={addOrEditModal}
              handleClose={addOrEditModalCloseModal}
              type={moduleType}
              trigger={getCommunicationDetails}
              data={userDetails}
            />
          </Grid>
          <Grid>
            <SuccessAlert
              alertMsg={alrtMsg}
              open={successAlrtOpen}
              close={closeSuccess}
            />
            <ErrorAlert
              alertMsg={alrtMsg}
              open={errorAlrtOpen}
              close={closeError}
            />
          </Grid>
        </>
      )}
    </>
  );
};

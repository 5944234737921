import { Routes, Route } from "react-router-dom";
import Layout from "../pages/common_pages/layout";
import { DashBoard } from "../pages/management_pages/dashboard";
import Chef from "../pages/management_pages/chefs";
import Consumer from "../pages/management_pages/consumers";
import ContactUs from "../pages/management_pages/contact_us";
import Csr from "../pages/management_pages/csr";
import Faq from "../pages/management_pages/faq";
import IncompleteOnBoarding from "../pages/management_pages/incomplete_onboarding";
import IncompleteOrders from "../pages/management_pages/incomplete_orders";
import Manager from "../pages/management_pages/manager";
import Onboarding from "../pages/management_pages/onboarding";
import Orders from "../pages/management_pages/orders";
import Pricing from "../pages/management_pages/pricing";
import Rewards from "../pages/management_pages/rewards";
import StoreCredits from "../pages/management_pages/store_credits";
import SuperAdmin from "../pages/management_pages/super_admin";
import Supervisor from "../pages/management_pages/supervisor";
import Tickets from "../pages/management_pages/tickets";
import GlobalOperations from "../pages/management_pages/global_operations";
// import ConsumerAbout from "../pages/consumer/about";
import { ConsumerAbout } from "../pages/common_pages/about";
import { ConsumerDetails, ChefDetails } from "../pages/common_pages/details";
import { PrivateRoute } from "./private_router";
import { NotFound } from "../pages/common_pages/not_found";
import UserAdmin from "../pages/management_pages/useradmin";
import TermsAndConditions from "../pages/management_pages/terms_and_conditons";
import PrivacyPolicy from "../pages/management_pages/privacy_policy";
import { CurrencyTax } from "../pages/management_pages/currentTax";
import { Notifications } from "../pages/management_pages/notifications";
import Chat from "../pages/management_pages/chat";
import OrderBreakDown from "../pages/management_pages/ordersBreakDown";
import OrderReports from "../pages/management_pages/orders_reports";
import OrdersReportMessage from "../pages/management_pages/order_report_message";
import DynamicText from "../pages/management_pages/dynamic_text";
import CommercialKitchen from "../pages/management_pages/commercialKitchen";
import PublicKitchen from "../pages/management_pages/publicKitchen";
import RevenueDetails from "../pages/management_pages/revenue";
import ChangePassword from "../pages/management_pages/changePassword";

const ManagementRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PrivateRoute component={DashBoard} />} />

          <Route
            path="/dashboard"
            element={<PrivateRoute component={DashBoard} />}
          />
          <Route
            path="/globalOperations"
            element={<PrivateRoute component={GlobalOperations} />}
          />
          <Route
            path="/userAdmin"
            element={<PrivateRoute component={UserAdmin} />}
          />
          <Route path="/chef" element={<PrivateRoute component={Chef} />}>
            <Route index element={<PrivateRoute component={ChefDetails} />} />
            <Route
              path="details"
              element={<PrivateRoute component={ConsumerDetails} />}
            />
            <Route
              path="about"
              element={<PrivateRoute component={ConsumerAbout} />}
            />
          </Route>
          <Route
            path="/Consumer"
            element={<PrivateRoute component={Consumer} />}
          >
            <Route
              index
              element={<PrivateRoute component={ConsumerDetails} />}
            />
            <Route
              path="details"
              element={<PrivateRoute component={ConsumerDetails} />}
            />
            <Route
              path="about"
              element={<PrivateRoute component={ConsumerAbout} />}
            />
          </Route>
          <Route
            path="/contactUs"
            element={<PrivateRoute component={ContactUs} />}
          />
          <Route
            path="/notifications"
            element={<PrivateRoute component={Notifications} />}
          />
          <Route
            path="/reportChefMessage"
            element={<PrivateRoute component={OrdersReportMessage} />}
          />
          <Route
            path="/ordersBreakDown"
            element={<PrivateRoute component={OrderBreakDown} />}
          />
          <Route
            path="/termsAndConditions"
            element={<PrivateRoute component={TermsAndConditions} />}
          />
          <Route
            path="/privacyPolicy"
            element={<PrivateRoute component={PrivacyPolicy} />}
          />
          <Route
            path="/currencyTax"
            element={<PrivateRoute component={CurrencyTax} />}
          />
          <Route path="/csr" element={<PrivateRoute component={Csr} />} />
          <Route path="/faq" element={<PrivateRoute component={Faq} />} />
          <Route
            path="/incompleteOnBoarding"
            element={<PrivateRoute component={IncompleteOnBoarding} />}
          />
          <Route
            path="/incompleteOrders"
            element={<PrivateRoute component={IncompleteOrders} />}
          />
          <Route
            path="/manager"
            element={<PrivateRoute component={Manager} />}
          />
          <Route
            path="/onboarding"
            element={<PrivateRoute component={Onboarding} />}
          />
          <Route path="/orders" element={<PrivateRoute component={Orders} />} />
          <Route
            path="/ordersReports"
            element={<PrivateRoute component={OrderReports} />}
          />
          <Route
            path="/pricing"
            element={<PrivateRoute component={Pricing} />}
          />
          <Route
            path="/rewards"
            element={<PrivateRoute component={Rewards} />}
          />
          <Route
            path="/publicKitchen"
            element={<PrivateRoute component={PublicKitchen} />}
          />
          <Route
            path="/storeCredits"
            element={<PrivateRoute component={StoreCredits} />}
          />
          <Route
            path="/superAdmin"
            element={<PrivateRoute component={SuperAdmin} />}
          />
          <Route
            path="/dynamicText"
            element={<PrivateRoute component={DynamicText} />}
          />
          <Route path="/chefRevenueLimit" element={<RevenueDetails />} />
          <Route
            path="/supervisor"
            element={<PrivateRoute component={Supervisor} />}
          />
          <Route
            path="/tickets"
            element={<PrivateRoute component={Tickets} />}
          />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route
            path="/commercialKitchen"
            element={<PrivateRoute component={CommercialKitchen} />}
          />
          <Route path="/TicketId" element={<Chat />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default ManagementRoutes;

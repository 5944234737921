import * as React from "react";
import { Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TitleComponent } from "../../components/title_component";
import { tabStyle, tabItems } from "../../styles/common_styles";
import { PersonalDetails } from "./personalDetails";
import { Consumerorders } from "./orders";
import { ConsumerReviews } from "./reviews";
import { Consumercommunication } from "./communication";
import { useLocation } from "react-router-dom";
import { tabHeader, onboardingTab } from "../../styles/common_styles";

export const ConsumerAbout = () => {
  const location = useLocation();
  const moduleType = location.state?.module;
  const type = location.state?.type;
  const userId = location.state?.userId;
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const titleText = moduleType === "1" ? "Consumers" : "Chefs";

  const tabList = (
    <TabList
      sx={tabStyle}
      onChange={handleChange}
      aria-label="lab API tabs example"
    >
      <Tab label="About" value="1" sx={tabItems} />
      <Tab label="Orders" value="2" sx={tabItems} />
      {moduleType === 1 ? (
        <Tab label="Consumer Reviews" value="4" sx={tabItems} />
      ) : (
        <Tab label="Chefs Reviews" value="3" sx={tabItems} />
      )}
      <Tab label="Communication" value="5" sx={tabItems} />
    </TabList>
  );

  return (
    <>
      <Grid
        sx={tabHeader}
        container
        justifyContent="space-between"
        alignItems="center"
        pl={3}
        pr={3}
      >
        <Grid item md={2}>
          <TitleComponent title={titleText} />
        </Grid>
        {/* {type === 11 ? (
          ""
        ) : ( */}
        <Grid item md={8}>
          <Grid container direction={"row"} alignItems="center" spacing={2}>
            <Grid item sx={{ width: "inherit" }}>
              <TabContext value={value}>{tabList}</TabContext>
            </Grid>
          </Grid>
        </Grid>
        {/* )} */}
      </Grid>

      {/* TabPanel component moved after the parent Grid */}
      <TabContext value={value}>
        <TabPanel value="1" sx={onboardingTab}>
          <PersonalDetails personId={userId} fromModule={moduleType} />
        </TabPanel>
        <TabPanel value="2">
          <Consumerorders />
        </TabPanel>
        <TabPanel value="3">
          <ConsumerReviews personId={userId} fromModule={moduleType} />
        </TabPanel>
        <TabPanel value="4">
          <ConsumerReviews personId={userId} fromModule={moduleType} />
        </TabPanel>
        <TabPanel value="5">
          <Consumercommunication />
        </TabPanel>
      </TabContext>
    </>
  );
};

import {
  LayOutColors,
  searchFieldColor,
  ButtonColors,
  componentTitleColor,
  IconColors,
  TableColor,
  ErrorColor,
  PaginationColors,
  DashBoardColors,
} from "./global_colors";

import chef from "../assets/images/chef.png";
import veggies from "../assets/images/veggies.png";
import vector from "../assets/images/vectorline.png";
import consumer from "../assets/images/consumer.png";
import chef2 from "../assets/images/chef2.png";
import user from "../assets/images/userapp.png";
import chefanduser from "../assets/images/chefanduser.png";

export const LayoutStyle = {
  width: "100vw",
  height: "100vh",
  backgroundColor: LayOutColors.LayOutbackground,
  overflow: "hidden",
};

export const SideNavStyle = {
  width: "100%",
  height: "100vh",
  backgroundColor: LayOutColors.sideNavBackGround,
  boxShadow: "10px 0px 40px 5px rgba(19, 142, 255, 0.05)",
};

export const HeaderStyle = {
  width: "100%",
  height: "12vh",
  backgroundColor: LayOutColors.headerBackGround,
  boxShadow: "5px 10px 40px 5px rgba(19, 142, 255, 0.05)",
  alignItems: "center",
};

export const LayoutContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "88vh",
};

export const LayoutBodyStyle = {
  width: "98%",
  height: "97%",
  borderRadius: "10px",
  backgroundColor: LayOutColors.bodyBackGround,
  boxShadow: "5px 10px 40px 5px rgba(19, 142, 255, 0.05)",
  overflowY: "scroll",
};

export const ProfileImage = {
  height: "60px",
  width: "60px",
  bordeRadius: "30px",
};

export const profilestyle = {
  display: "flex",
  alignItems: "center",
};

export const notificationStyle = {
  display: "flex",
  height: "50px",
  width: "50px",
  backgroundColor: "#EBEEF6",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 2,
};

export const profileImgStyle = {
  display: "flex",
  height: "50px",
  width: "50px",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 10px",
  cursor: "pointer",
};

export const searchField = {
  backgroundColor: searchFieldColor.backGround,
  borderRadius: "30px !important",
  // marginRight: "20px ",
};

export const addButton = {
  color: ButtonColors.color,
  backgroundColor: ButtonColors.backGround,
  borderRadius: "30px !important",
  textAlign: "center",
  height: "40px",
  width: "121px",
  flexDirection: "column",
  alignItems: "flex-center",
  gap: "4px",
  boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  ":hover": {
    color: ButtonColors.color,
    backgroundColor: ButtonColors.backGround,
    borderRadius: "30px !important",
    width: "121px",
    height: "40px",
    flexDirection: "column",
    alignItems: "flex-center",
    gap: "4px",
    boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  },
};
export const defaultAddButton = {
  color: ButtonColors.color,
  backgroundColor: "grey",
  borderRadius: "30px !important",
  textAlign: "center",
  height: "40px",
  width: "121px",
  flexDirection: "column",
  alignItems: "flex-center",
  gap: "4px",
  boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  ":hover": {
    color: ButtonColors.color,
    backgroundColor: "grey",
    borderRadius: "30px !important",
    width: "121px",
    height: "40px",
    flexDirection: "column",
    alignItems: "flex-center",
    gap: "4px",
    boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  },
};

export const ButtonLarge = {
  color: ButtonColors.color,
  backgroundColor: ButtonColors.backGround,
  borderRadius: "30px !important",
  textAlign: "center",
  height: "40px",
  width: "200px",
  flexDirection: "column",
  alignItems: "flex-center",
  gap: "4px",
  boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  ":hover": {
    color: ButtonColors.color,
    backgroundColor: ButtonColors.backGround,
    borderRadius: "30px !important",
    width: "200px",
    height: "40px",
    flexDirection: "column",
    alignItems: "flex-center",
    gap: "4px",
    boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  },
};

export const ButtonStyle = {
  color: "black",
  backgroundColor: "transparent",
  borderRadius: "30px !important",
  textAlign: "center",
  height: "40px",
  width: "170px",
  flexDirection: "column",
  alignItems: "flex-center",
  gap: "4px",
  boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  border: "1px solid black",
};

export const ButtonStyle1 = {
  color: "white",
  backgroundColor: "black",
  borderRadius: "30px !important",
  textAlign: "center",
  height: "40px",
  width: "170px",
  flexDirection: "column",
  alignItems: "flex-center",
  gap: "4px",
  boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  border: "1px solid black",
  ":hover": {
    color: "white",
    backgroundColor: "black",
  },
};
export const ButtonStyle2 = {
  color: "white",
  backgroundColor: "black",
  borderRadius: "30px !important",
  textAlign: "center",
  height: "40px",
  width: "90px",
  flexDirection: "column",
  alignItems: "flex-center",
  gap: "4px",
  boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
  border: "1px solid black",
  ":hover": {
    color: "white",
    backgroundColor: "black",
  },
};

export const titleName = {
  color: componentTitleColor.color,
  fontSize: "32px",
  fontWeight: "600",
};

export const editIcon = {
  color: IconColors.eidtIconColor,
};

export const deleteIcon = {
  color: IconColors.deleteIconColor,
};

export const tableHeadCell = {
  backgroundColor: TableColor.tableHeadBackGround,
  color: TableColor.tableHeadColor,
  fontSize: "16px",
  fontWeight: "600",
  textAlign: "center",
  whiteSpace: "nowrap",
};

export const tableBodyCell = {
  color: TableColor.tableBodyColor,
  whiteSpace: "nowrap",
  fontSize: "14px",
  textAlign: "center",
};

export const tableCellStyles = {
  width: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  color: TableColor.tableBodyColor,
  textAlign: "center",
};

export const firstTableCellStyles = {
  width: "100px",
  textAlign: "center",
};

export const navLinkStyle = {
  color: LayOutColors.sideNavTextColor,
  textDecoration: "none",
};

export const Userheading = {
  fontSize: "20px !important",
  fontWeight: "600 !important",
  fontFamily: "Poppins !important",
  padding: " 20px !important",
};

export const Cardstyle = {
  height: "116px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "#FFF",
  boxShadow: "0px 0px 45.069122314453125px 0px rgba(138, 138, 138, 0.15)",
  cursor: "pointer",
};

export const Useradminheading = {
  color: DashBoardColors.count,
  fontSize: "22px !important",
  fontWeight: " 600 !important",
  fontFamily: "Poppins !important",
};

export const Paragraph = {
  color: DashBoardColors.paragraph,
  fontSize: "14px !important",
  fontFamily: "Poppins !important",
};

export const VisibilityStyle = {
  color: "white",
  height: "2.5vh",
  border: "0px",
  borderRadius: "0px",
};

export const IconButtonStyle = {
  backgroundColor: "black",
  height: "3vh",
  width: "5vw",
  borderRadius: "6px",
  alignSelf: "center",
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  height: "520px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
  margin: 0,
  padding: 0,
};

export const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  height: "520px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
  margin: 0,
  padding: 0,
};

export const imgmodalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "420px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
  margin: 0,
  padding: 0,
};

export const viewmodalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  height: "650px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
  margin: 0,
  padding: 0,
};

export const contactModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "726px",
  height: "480px",
  bgcolor: "background.paper",
  borderRadius: "20px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
  display: "flex",
  flexDirection: "column",
};

export const profileModal = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "848px",
  height: "470px",
  bgcolor: "background.paper",
  borderRadius: "20px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
  display: "flex",
  flexDirection: "column",
};

export const FaqModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  height: "auto",
  maxHeight: "450px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflowY: "scroll",
  boxShadow: 24,
  outline: 0,
  margin: 0,
  padding: 0,
};

export const CommunicationmodalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "380px",
  height: "450px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
};
export const MessagemodalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "380px",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflowY: "scroll",
  boxShadow: 24,
  p: 4,
  outline: 0,
};

export const blockOrDeleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  outline: 0,
};

export const modalHeading = {
  fontSize: "20px",
  fontWeight: "600",
  textAlign: "center",
  padding: "20px !important",
};

export const roleDetailStyle = {
  color: "rgba(132, 146, 166, 1)",
  fontWeight: "400",
};

export const tabStyle = {
  borderRadius: "100px",
  backgroundColor: "#F8F9FA",
  boxShadow: "0px 0px 2.906px 0px rgba(60, 72, 88, 0.15)",
  color: "black",
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
  },
  "& .MuiTabs-indicator": {
    display: "none", // Hides the indicator
  },
};

export const tabItem = {
  color: "black",
  fontSize: "14px",
  fontWeight: "600",
  width: "50%",
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
    borderRadius: "30px",
  },
};

export const tabItems = {
  color: "black",
  fontSize: "14px",
  fontWeight: "600",
  width: "25%",
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
    borderRadius: "30px",
  },
};

export const Errors = {
  color: ErrorColor.errorColor,
};

export const IconStyles = {
  cursor: "pointer",
  position: "absolute",
  right: "17px",
  top: "18px",
};

export const cardTitle = {
  fontSize: "26px",
  fontWeight: "700",
  textAlign: "center",
};

export const safetyHeading = {
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "10px !important",
};

export const safetyText = {
  fontSize: "16px",
  fontWeight: 400,
  color: "#756F6F",
  marginBottom: "10px !important",
};

export const safetyMainHeading = {
  fontSize: "22px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "10px !important",
};

export const safetyBorder = {
  borderRight: "1px solid #CCCCCC",
};

export const safetyBlock = {
  borderBottom: "1px dashed #CCCCCC",
  paddingBottom: "1.5rem",
};

export const tabHeader = {
  height: "20vh",
};

export const SpanStyle = {
  color: "#8492A6",
};

export const OrderStyle = {
  color: "black",
};

export const OrderTotalStyle = {
  color: "orange",
  fontSize: "20px",
};

export const PaginationContainer = {
  color: PaginationColors.TextColor,
  padding: "10px",
  height: "8%",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
};

export const selectCheckBoxStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const tableContainerStyles = {
  height: "100%",
};

export const tableContainerParent = {
  margin: "0px 20px",
  height: "90%",
};

export const moduleHeadContainer = {
  height: "18%",
  padding: "0 20px",
};

export const tableParentHolder = {
  height: "80%",
};
export const tableParentHolderr = {
  height: "70vh",
  // overflowY: "scroll",
  // marginTop: "20px",
};

export const rewardsContainer = {
  height: "80%",
  overflowY: "scroll",
  padding: "10px",
};

export const paperContainerStyles = {
  width: "100%",
  height: "100%",
};

export const onboardingTab = {
  height: "67%",
  overflowY: "scroll",
};

export const PriceTableContainer = {
  borderRadius: "10px",
  overflow: "hidden",
};

export const PricetableHeaderCell = {
  backgroundColor: "#000000",
  color: "white",
  fontWeight: "bold",
  textAlign: "center",
};

export const PricetableHeaderCellStyle = {
  backgroundColor: "#000000",
  color: "white",
  fontWeight: "bold",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const pricingText = {
  height: "2%",
  width: "10%",
};

export const selectStyle = {
  color: "white",
};

export const PricetableRowCell = {
  textAlign: "center",
};

export const priceHeading = {
  paddingBottom: "2px",
  fontWeight: "bold",
};

export const container1 = {
  height: "602px",
  backgroundImage: `url(${chef})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
};
export const container1_2 = {
  height: "94vh",
  backgroundImage: `url(${chef})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
};

export const container1_1 = {
  padding: "40px",
};

export const smallLogo = {
  width: "100px",
};

export const container2_1 = {
  height: "300px",
  backgroundImage: `url(${veggies})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

export const container3 = {
  height: "477px",
  backgroundImage: `url(${vector})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

export const container3_1 = {
  height: "477px",
  backgroundImage: `url(${consumer})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

export const container3_2 = {
  height: "477px",
  backgroundImage: `url(${chef2})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export const container3_2_text = {
  fontWeight: 800,
  fontSize: "32px",
};

export const container4 = {
  height: "627px",
  backgroundImage: `url(${user})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

export const container4_1 = {
  padding: "40px",
  height: "627px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  textAlign: "left",
  marginLeft: "auto",
  whiteSpace: "pre-line",
};

export const container5 = {
  height: "500px",
  backgroundImage: `url(${chefanduser})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  textAlign: "center",
  alignItems: "center",
  position: "relative",
};

export const container6 = {
  height: "50px",
  backgroundColor: componentTitleColor.color,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  color: "#fff",
};

export const container1_1_text = {
  fontWeight: 900,
  fontSize: "50px",
  fontStyle: "jost",
};

export const container1_1_text1 = {
  fontWeight: 700,
  fontSize: "22px",
  color: "#A7A7A7",
};

export const container5_1_text1 = {
  fontWeight: 700,
  fontSize: "22px",
  color: "#A7A7A7",
  textAlign: "center",
  padding: "100px 170px 30px 170px",
};

export const container5_1_text2 = {
  fontWeight: 800,
  fontSize: "32px",
  textAlign: "center",
};

export const contactUsButton = {
  width: "200px",
  height: "40px",
  borderRadius: "300px",
  backgroundColor: "#000000",
  boxShadow: "0px 0px 5px 0px #00000033",
  color: "#fff",
  position: "absolute",
  bottom: "100px",
  left: 0,
  right: 0,
  margin: "auto",
  ":hover": {
    width: "200px",
    height: "40px",
    borderRadius: "300px",
    backgroundColor: "#000000",
    boxShadow: "0px 0px 5px 0px #00000033",
    color: "#fff",
    position: "absolute",
    bottom: "100px",
    left: 0,
    right: 0,
    margin: "auto",
  },
};

export const container5_2 = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export const countryHolder = {
  fontSize: "22px",
  fontWeight: 800,
};

export const contactHolder = {
  fontSize: "22px",
  fontWeight: 400,
};

export const contactSubmitButton = {
  width: "200px",
  height: "40px",
  borderRadius: "300px",
  backgroundColor: "#000000",
  boxShadow: "0px 0px 5px 0px #00000033",
  color: "#fff",
  ":hover": {
    width: "200px",
    height: "40px",
    borderRadius: "300px",
    backgroundColor: "#000000",
    boxShadow: "0px 0px 5px 0px #00000033",
    color: "#fff",
  },
};

export const cardContainer = {
  width: "450px",
  bordeRadius: "10px !important",
  boxShadow: "0px 0px 30px 0px #0000000D",
};

export const profileEdit = {
  position: "absolute",
  top: 30,
  right: 55,
  cursor: "pointer",
  color: "#19B200",
};

export const rewardFieldSet = {
  borderRadius: "10px !important",
  width: "100px",
  height: "50px",
  border: "none",
  "& .MuiInputBase-root": {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: "20px !important",
  },
};
export const formControlAdjust = {
  width: " 180px",
  whiteSpace: "nowrap",
  overflow: "hidden !important",
  textOverflow: "ellipsis",
  "& .MuiSelect-select": {
    paddingRight: "20px !important",
  },
};

export const persondetailsContainer = {
  height: "200px",
  width: "210px",
  backgroundColor: "lightgray",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
};

export const pdfLink = {
  color: "blue",
  textDecoration: "underline",
};

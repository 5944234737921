import React, { useState } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { Emaillable, CustomField, Emaillablee } from "../../styles/loginstyles";
import { CommonServices } from "../../services/common_services";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemText from "@mui/material/ListItemText";
import { ButtonComponent } from "../../components/form_components/button";
import "../../styles/common_styles.css";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { tabHeader } from "../../styles/common_styles";

export const Notifications = () => {
  const [countries, setCountries] = React.useState([]);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(0);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [selectedConsumers, setSelectedConsumers] = useState([]);
  const [selectedChefs, setSelectedChefs] = useState([]);
  const [consumerList, setConsumerList] = useState([]);
  const [chefList, setChefList] = useState([]);
  const [checkStatus1, setCheckStatus1] = useState(false);
  const [checkStatus2, setCheckStatus2] = useState(false);
  const [title, setTitle] = useState("");
  const [notification, setNotification] = useState("");
  const [selectAllConsumer, setSelectAllConsumer] = useState(false);
  const [selectAllChef, setSelectAllChef] = useState(false);

  const getConsumerList = async () => {
    const payload = {
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      search: "",
    };
    try {
      const totalConsumers = await ManagementServices.getAllConsumersList(
        payload
      );
      if (totalConsumers.status === 1) {
        setConsumerList(totalConsumers.data.consumers);
        // setSuccessAlrtOpen(true);
        // setAlrtMsg(totalConsumers.message);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(totalConsumers.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const getChefList = async () => {
    const payload = {
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      search: "",
    };
    try {
      const totalChefs = await ManagementServices.getAllChefsList(payload);
      if (totalChefs.status === 1) {
        setChefList(totalChefs.data.chefs);
        // setSuccessAlrtOpen(true);
        // setAlrtMsg(totalChefs.message);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(totalChefs.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const handleChangeFeild = (e, fieldName) => {
    if (fieldName === "title") {
      setTitle(e.target.value);
    } else {
      setNotification(e.target.value);
    }
  };

  const handleSave = async () => {
    const payload = {
      title: title,
      message: notification,
      chefIds: selectedChefs,
      consumerIds: selectedConsumers,
    };
    try {
      const addNotification = await ManagementServices.sendNotifications(
        payload
      );
      if (addNotification.status === 1) {
        setSelectedChefs([]);
        setSelectedConsumers([]);
        setTitle("");
        setNotification("");
        setSuccessAlrtOpen(true);
        setAlrtMsg(addNotification.message);
        setCheckStatus1(false);
        setCheckStatus2(false);
        setSelectedCountry(0);
        setSelectedState(0);
        setSelectedCity(0);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(addNotification.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(0);
    setSelectedCity(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(3, value, selectedCountry);
    setSelectedCity(0);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const handleSelectAllConsumers = (e, type) => {
    if (e.target.checked && type !== "chef") {
      setSelectAllConsumer(e.target.checked);
      const allIds = consumerList.map((consumer) => consumer.id);
      setSelectedConsumers(allIds);
    } else {
      setSelectAllConsumer(e.target.checked);
      setSelectedConsumers([]);
    }
  };

  const handleSelectAllChefs = (e, type) => {
    if (e.target.checked) {
      setSelectAllChef(e.target.checked && type !== "consumer");
      const allIds = chefList.map((chef) => chef.id);
      setSelectedChefs(allIds);
    } else {
      setSelectAllChef(e.target.checked);
      setSelectedChefs([]);
    }
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);
      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: 2,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: 3,
        country: selectedCountry,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);

      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  React.useEffect(() => {
    getConsumerList();
    getChefList();
    GetCountries();
  }, [selectedCountry, selectedState, selectedCity]);

  return (
    <>
      <Grid container p={3}>
        <Grid item md={12} height={"10vh"}>
          <TitleComponent title={"Notification"} />
        </Grid>
        <Grid item md={12} height={"60vh"}>
          <Grid container item md={12} gap={2}>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Grid sx={Emaillable}>Country</Grid>
                <Select
                  sx={CustomField}
                  value={selectedCountry}
                  size={"small"}
                  onChange={handleChangeCountry}
                  displayEmpty
                  inputProps={{
                    "aria-label": "countryId",
                    sx: { "& input": { color: "#CCCCCC" } },
                  }}
                >
                  <MenuItem value={0}>Select country</MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Grid sx={Emaillable}>State</Grid>
                <Select
                  sx={CustomField}
                  value={selectedState}
                  size={"small"}
                  onChange={handleChangeState}
                  displayEmpty
                  inputProps={{
                    "aria-label": "stateId",
                    sx: { "& input": { color: "#CCCCCC" } },
                  }}
                >
                  <MenuItem value={0}>Select state</MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Grid sx={Emaillable}>City</Grid>
                <Select
                  sx={CustomField}
                  value={selectedCity}
                  size={"small"}
                  displayEmpty
                  inputProps={{
                    "aria-label": "cityId",
                    sx: { "& input": { color: "#CCCCCC" } },
                  }}
                  onChange={handleChangeCity}
                >
                  <MenuItem value={0}>Select City</MenuItem>
                  {totalCities}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item md={12} gap={4} pt={5}>
            <Grid
              item
              md={4.5}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "end",
              }}
              gap={3}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                }}
              >
                <Grid>
                  <Typography>Consumer</Typography>
                </Grid>
                <Grid>
                  <Checkbox
                    className="checkStyles"
                    checked={checkStatus1}
                    onChange={(e) => setCheckStatus1(e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center ",
                  alignItems: "end",
                  flexDirection: "row",
                }}
              >
                <Grid>
                  <Typography>Chef</Typography>
                </Grid>
                <Grid>
                  <Checkbox
                    className="checkStyles"
                    checked={checkStatus2}
                    onChange={(e) => setCheckStatus2(e.target.checked)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              gap={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {checkStatus1 && (
                <Grid item md={4.5}>
                  <Grid sx={Emaillablee} pl={6}>
                    Consumer
                  </Grid>
                  <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid>
                      <Checkbox
                        className="checkStyles"
                        checked={selectAllConsumer}
                        onChange={(e) =>
                          handleSelectAllConsumers(e, "consumer")
                        }
                      />
                    </Grid>
                    <Autocomplete
                      fullWidth
                      multiple
                      size={"small"}
                      id="checkboxes-tagss-demo"
                      defaultValue={selectedConsumers || []}
                      options={consumerList}
                      disableCloseOnSelect
                      onChange={(event, newValue) => {
                        const selectedIds = newValue.map((option) => option.id);
                        setSelectedConsumers(selectedIds);
                      }}
                      getOptionLabel={(option) =>
                        `${option.first_name} - ${option.mobile}`
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            className="checkStyless"
                          />
                          <ListItemText
                            primary={option.first_name}
                            secondary={
                              `${option.email}, ${option.mobile}` || ""
                            }
                          />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Consumers"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              {checkStatus2 && (
                <Grid item md={4.5}>
                  <Grid sx={Emaillablee} pl={6}>
                    Chef
                  </Grid>
                  <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid>
                      <Checkbox
                        className="checkStyles"
                        mr={4}
                        checked={selectAllChef}
                        onChange={(e) => handleSelectAllChefs(e, "chef")}
                      />
                    </Grid>
                    <Autocomplete
                      fullWidth
                      multiple
                      size={"small"}
                      id="checkboxes-tags-demo"
                      defaultValue={selectedChefs || []}
                      options={chefList}
                      disableCloseOnSelect
                      onChange={(event, newValue) => {
                        const selectedIds = newValue.map((option) => option.id);
                        setSelectedChefs(selectedIds);
                      }}
                      getOptionLabel={(option) =>
                        `${option.first_name} - ${option.mobile}`
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            className="checkStyless"
                          />
                          <ListItemText
                            primary={option.first_name}
                            secondary={
                              `${option.email}, ${option.mobile}` || ""
                            }
                          />
                        </li>
                      )}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Chefs"
                          />
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container>
                <Grid item md={4.5}>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>Title</Grid>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Title"
                      value={title ? title : ""}
                      onChange={(e) => handleChangeFeild(e, "title")}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={4.5}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Notification Text</Grid>
                  <TextareaAutosize
                    className={"textArea"}
                    value={notification ? notification : ""}
                    placeholder="Notification Text"
                    onChange={(e) => handleChangeFeild(e, "notification")}
                  />
                </FormControl>
              </Grid>
              <Grid item container justifyContent={"center"} md={4.5} pt={4}>
                <ButtonComponent
                  type={"button"}
                  btnText={"Send"}
                  onClick={handleSave}
                />
              </Grid>
              <SuccessAlert
                alertMsg={alrtMsg}
                open={successAlrtOpen}
                close={closeSuccess}
              />
              <ErrorAlert
                alertMsg={alrtMsg}
                open={errorAlrtOpen}
                close={closeError}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

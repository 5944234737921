import { Card, CardMedia } from "@mui/material";
// import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import YoufoodUserLogo from "../assets/images/Logo.png";

export const LogoCard = () => {
  const Navigate = useNavigate();
  const handleClick = () => {
    Navigate("/");
  };

  return (
    <Card sx={{ width: 150, height: 130, padding: 2, boxShadow: "none" }}>
      {/* <CardMedia
        onClick={handleClick}
        sx={{
          maxHeight: "130px",
          width: "100%",
          cursor: "pointer",
          objectFit: "contain",
        }}
        image={YoufoodUserLogo}
      /> */}
      <img
        src={YoufoodUserLogo}
        onClick={handleClick}
        alt="logo"
        style={{
          width: "130px",
          height: "130px",
          borderRadius: "15px",
          margin: "5px",
        }}
      />
    </Card>
  );
};

import { db } from "../components/firebase-config";
import {
  setDoc,
  getDocs,
  getDoc,
  collection,
  // addDoc,
  // setDoc,
  query,
  // updateDoc,
  doc,
  where,
} from "firebase/firestore";

// await FirebaseServices.setMessageDoc(docRef, messageData);

export const FirebaseServices = {
  setMessageDoc: async (docRef, data) => {
    try {
      const setMessageData = await setDoc(docRef, data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  setUpdateBadgeCountDoc: async (docRef, data) => {
    try {
      const setMessageData = await setDoc(docRef, data, { merge: true });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  setNotUpdateBadgeCountDoc: async (docRef, data) => {
    try {
      const setMessageData = await setDoc(docRef, data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  updateMemberIdsSetDoc: async (docRef, data) => {
    try {
      const setMessageData = await setDoc(docRef, data, { merge: true });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  updateLatestMemberIdsSetDoc: async (docRef, data) => {
    try {
      const setMessageData = await setDoc(docRef, data, { merge: true });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },
  updatenotMemberIdsSetDoc: async (docRef, data) => {
    try {
      const setMessageData = await setDoc(docRef, data, { merge: true });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  assignTicketDoc: async (docRef, data) => {
    try {
      const setMessageData = await setDoc(docRef, data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },
  getBadgeCountOnTicket: async (TicketId) => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "userbadge_stage"),
          where(`ticketBadge.${`${TicketId}_Web`}`, "!=", null)
        )
      );

      if (!querySnapshot.empty) {
        let ticketBadge;
        querySnapshot.forEach((doc) => {
          ticketBadge = doc.data().ticketBadge;
        });

        if (ticketBadge[`${TicketId}_Web`] !== 0) {
          return ticketBadge[`${TicketId}_Web`];
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (e) {
      console.error("Error retrieving document: ", e);
      throw e;
    }
  },
  updateBadgeOnTicketOpen: async (TicketId) => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "userbadge_stage"),
          where(`ticketBadge.${`${TicketId}_Web`}`, "!=", null)
        )
      );
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const badgeDocRef = doc.ref;
          const ticketBadge = doc.data().ticketBadge;
          const updatedCount = 0; // Increment badge count by 1
          // Update the badge count for the specific ticketId

          const badgeCountData = {
            ticketBadge: {
              ...ticketBadge,
              [`${TicketId}_Web`]: updatedCount,
            },
          };
          const updateBadgeData = await setDoc(badgeDocRef, badgeCountData, {
            merge: true,
          });
        });
      }
    } catch (e) {
      console.error("Error retrieving document: ", e);
    }
  },
};

import React from "react";
import {
  Grid,
  Box,
  Modal,
  FormControl,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { CommunicationmodalStyle } from "../styles/common_styles";
import { ButtonComponent } from "./form_components/button";
import { Emaillable } from "../styles/loginstyles";
import { FormProvider, useForm } from "react-hook-form";
import { Errors } from "../styles/common_styles";
import { CustomField } from "../styles/loginstyles";
import { useLocation } from "react-router-dom";
import { ManagementServices } from "../services/management_services";
import { SuccessAlert } from "./alert";
import { ErrorAlert } from "./alert";

export const CommunicationModalComponent = ({
  open,
  handleClose,
  type,
  trigger,
  data,
}) => {
  const location = useLocation();
  // const moduleType = location.state?.module;
  const userId = location.state?.userId;
  const methods = useForm();
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const onSubmit1 = async (data) => {
    if (type === 1 || type === "1") {
      try {
        const payload = {
          userId: userId,
          name: data.Name,
          email: data.Email,
          message: data.Message,
          type: 1,
        };
        const insertCommunication =
          await ManagementServices.InsertCommunication(payload);
        if (insertCommunication.status === 1) {
          setAlrtMsg(insertCommunication.message);
          setSuccessAlrtOpen(true);
          handleClose();
          trigger();
        } else {
          setAlrtMsg(insertCommunication.message);
          setErrorAlrtOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const payload = {
          userId: userId,
          name: data.Name,
          email: data.Email,
          message: data.Message,
          type: 2,
        };
        const insertCommunication =
          await ManagementServices.InsertCommunication(payload);
        if (insertCommunication.status === 1) {
          setAlrtMsg(insertCommunication.message);
          setSuccessAlrtOpen(true);
          handleClose();
          trigger();
        } else {
          setAlrtMsg(insertCommunication.message);
          setErrorAlrtOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    // GetCountries();
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={CommunicationmodalStyle}>
            <Grid container flexDirection={"column"} justifyContent={"center"}>
              <form onSubmit={handleSubmit(onSubmit1)}>
                <Grid item>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>Name</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      displayEmpty
                      size={"small"}
                      type="text"
                      value={data.first_name}
                      inputProps={{
                        placeholder: "Please enter Name",
                      }}
                      {...register("Name", {
                        required: "Please enter Name",
                      })}
                    />
                    {errors.Name && (
                      <Typography sx={Errors}>Please enter Name.</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>Email</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      displayEmpty
                      size={"small"}
                      type="text"
                      value={data.email}
                      inputProps={{
                        placeholder: "Please enter Email",
                      }}
                      {...register("Email", {
                        required: "Please enter Email",
                      })}
                    />
                    {errors.Name && (
                      <Typography sx={Errors}>Please enter Email.</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>Message</Grid>
                    <TextareaAutosize
                      className={"textArea"}
                      aria-label="empty textarea"
                      placeholder="Text here"
                      minRows={6}
                      inputProps={{
                        placeholder: "Please enter Message",
                      }}
                      {...register("Message", {
                        required: "Please enter Message",
                      })}
                    />
                    {errors.Message && (
                      <Typography sx={Errors}>Please enter Message.</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <Grid container justifyContent={"center"} mt={5}>
                    <ButtonComponent btnText={"Submit"} type="submit" />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Box>
        </Modal>
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </FormProvider>
    </>
  );
};

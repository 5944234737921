import React, { useState } from "react";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
import { ButtonStyle1 } from "../../styles/common_styles";
import { ManagementServices } from "../../services/management_services";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    // Make handleSubmit async
    e.preventDefault();
    setError("");
    setSuccess("");

    // Basic validation
    if (!oldPassword || !newPassword || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }

    const payload = {
      oldPassword,
      newPassword,
    };

    try {
      const changePasswordDetails = await ManagementServices.changePassword(
        payload
      );
      if (changePasswordDetails.status === 1) {
        setSuccess("Password changed successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setError(changePasswordDetails.message);
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "#white",
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          width: "100%",
          p: 3,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Change Password
        </Typography>

        <form onSubmit={handleSubmit} noValidate>
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="success.main">{success}</Typography>}

          <TextField
            label="Old Password"
            type="password"
            fullWidth
            margin="normal"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />

          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />

          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Grid sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button sx={ButtonStyle1} type="submit">
              Save
            </Button>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default ChangePassword;

import React from "react";
import { Grid, ListItemButton, ListItemText, Typography } from "@mui/material";
import { SideNavStyle } from "../../styles/common_styles";
import { NavLink } from "react-router-dom";
import "../../styles/common_styles.css";
import { StorageClient } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { BlockOrDeleteModalComponent } from "../modal_component";
import { LogoCard } from "../logo";
import { MediaBaseUrl } from "../../config/config";
import LogoutIcon from "../../assets/icons/logout.svg";
const SideNav = () => {
  const [sideNavData, setSideNavData] = React.useState([]);
  const Navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseLogOutModal = () => setOpenModal(false);

  const handleConfirmAction = (data) => {
    if (data === 1) {
      handleCloseLogOutModal();
    } else {
      StorageClient.clear();
      handleCloseLogOutModal();
      Navigate("/login");
    }
  };

  const GetSideNav = async () => {
    try {
      const sideNav = await StorageClient.getItem("rolePrivileges");
      if (sideNav !== undefined) {
        setSideNavData(sideNav);
      } else {
        StorageClient.clear();
        Navigate("/login");
      }
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  };

  const setRoleId = async (id) => {
    StorageClient.setItem("previlageId", id);
  };

  // eslint-disable-next-line
  React.useEffect(() => {
    GetSideNav();
  }, []);

  const navlinks = sideNavData.map((data) => {
    // GetRoleDetails()
    return (
      <NavLink
        key={data.id}
        className={"navLinkText"}
        to={data.path}
        onClick={() => {
          setRoleId(data.role_id);
        }}
      >
        <ListItemButton>
          <Grid className={"sideNavImg"}>
            <img src={MediaBaseUrl.iconsUrl + data.image} alt={data.image} />
          </Grid>
          <ListItemText
            className={"listItemText"}
            primary={data.privilege_name}
          />
        </ListItemButton>
      </NavLink>
    );
  });
  return (
    <>
      <Grid sx={SideNavStyle}>
        <Grid className={"navLinkBox"}>
          <Grid item>
            <Grid
              container
              // sx={{ cursor: "pointer" }}
              justifyContent={"center"}
              className={"logoCardBorder"}
            >
              <LogoCard />
            </Grid>
          </Grid>
          <Grid className={"navLinkContainer"}>
            <Grid item className="navScroll">
              {navlinks}
            </Grid>
            <Grid item>
              <NavLink className={"logOutText"}>
                <ListItemButton onClick={handleOpenModal}>
                  <Grid className={"sideNavImg"}>
                    <img src={LogoutIcon} alt="Logout" />
                  </Grid>
                  <ListItemText className={"listItemText"} primary={"Logout"} />
                </ListItemButton>
              </NavLink>
              <Typography textAlign={"center"}>
                V 1.4.6(API V 1.1.10)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BlockOrDeleteModalComponent
        deleteopen={openModal}
        onClose={handleCloseLogOutModal}
        title="Do you want to Logout?"
        resType={handleConfirmAction}
      />
    </>
  );
};

export default SideNav;

import * as React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { ButtonComponent } from "../../components/form_components/button";
import { TableComponent } from "../../components/table_component";
import {
  AddModalComponent,
  BlockOrDeleteModalComponent,
} from "../../components/modal_component";
import {
  tableHeadCell,
  moduleHeadContainer,
  tableParentHolder,
} from "../../styles/common_styles";
import { HFPagination } from "../../components/pagination";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { TableFilter } from "../../components/table_filter";
import { EditModalComponent } from "../../components/edit_modal_component";
import { ConvertDateString } from "../../utils/helpers";
import {
  TableCellComponent,
  IconTableCellComponent,
} from "../../components/table_cell_component";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import BlockIcon from "../../assets/icons/block.svg";
import UnBlockIcon from "../../assets/icons/unblock.svg";
import ViewIcon from "../../assets/icons/view.svg";
import { CommonServices } from "../../services/common_services";
import { Loader } from "../../components/loader_component";
import { GetAdminDetails } from "../../utils/helpers";
import ApproveIcon from "../../assets/icons/approval.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import { RoleDetailModal } from "../../components/roleDetailModal";

const Supervisor = () => {
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [addModal, setAddModal] = React.useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [glblOperation, setGlblOperations] = React.useState([]);
  const [searchData, setSearchData] = React.useState("");
  const [actionId, setActionId] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [adminrole, setAdminrole] = React.useState("");
  const [approveType, setApproveType] = React.useState(null);
  const [approveAction, setApproveAction] = React.useState("");
  const [adminDetails, setAdminDetails] = React.useState({});
  const [roleModal, setRoleModal] = React.useState(false);
  const [roleuserId, setRoleuserId] = React.useState("");

  const roleModalOpen = (id) => {
    setRoleModal(true);
    setRoleuserId(id);
  };
  const roleCloseModal = () => setRoleModal(false);

  const addModalHandleOpen = () => setAddModal(true);
  const addModalCloseModal = () => {
    setAddModal(false);
    getGlobalOperationsDetails();
  };
  const DeleteModalClose = () => setDeleteModal(false);

  const actionsHandleOpen = (data, title, type, approve) => {
    setActionId(data.id);
    setDeleteModal(true);
    setActiontype({ ...actionType, title, type, approve });
    switch (true) {
      case data.approval_status === 0:
        setApproveType(1);
        return;
      case data.delete_button_disable === true:
        setApproveType(2);
        return;
      case data.block_button_disable === true:
        setApproveType(3);
        setApproveAction("block");
        return;
      case data.unblock_button_disable === true:
        setApproveType(3);
        setApproveAction("unblock");
        return;
      case data.edit_button_disable === true:
        setApproveType(4);
        return;
      default:
        return;
    }
  };

  const settingAdmin = async () => {
    const AdminDetails = await GetAdminDetails();
    setAdminDetails(AdminDetails);
  };

  const editModalHandleOpen = (id) => {
    setEditModal(true);
    setEditId(id);
  };

  const editModalCloseModal = () => {
    setEditModal(false);
    setEditId("");
    getGlobalOperationsDetails();
  };

  const [filterState, setFilterState] = React.useState({
    blocked: false,
    unBlocked: false,
    edited: false,
    deleted: false,
    isPending: null,
  });

  const [actionType, setActiontype] = React.useState({
    title: "",
    type: null,
  });

  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const blockOrDeleteHandleOpen = (id, title, type) => {
    setActionId(id);
    setDeleteModal(true);
    setActiontype({ ...actionType, title, type });
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };
  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(null);
    setSelectedCity(null);
    getGlobalOperationsDetails();
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(null);
    GetCities(3, event.target.value, selectedCountry);
    getGlobalOperationsDetails();
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
    getGlobalOperationsDetails();
  };

  const FilterChangingValue = (selectedValue) => {
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
    setFilterState(selectedValue);
  };

  const handleRes = async (data) => {
    try {
      const payload = {
        userId: actionId,
        type: approveType,
        action: approveAction,
        isRejected: actionType.approve,
      };
      //handles No condition
      if (data === 1) {
        DeleteModalClose();
      } else {
        //handles block condition
        if (actionType.type === 1) {
          setLoaderData(true);
          const blockedUser = await ManagementServices.blockAdmin(actionId);
          if (blockedUser.status) {
            setLoaderData(false);
            setAlrtMsg(blockedUser.message);
            setSuccessAlrtOpen(true);
            getGlobalOperationsDetails();
            DeleteModalClose();
          } else {
            setAlrtMsg(blockedUser.message);
            setErrorAlrtOpen(true);
          }
        } else if (actionType.type === 3) {
          setLoaderData(true);
          const unblockedConsumer = await ManagementServices.unblockAdmin(
            actionId
          );
          if (unblockedConsumer.status === 1) {
            setLoaderData(false);
            setAlrtMsg(unblockedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getGlobalOperationsDetails();
          } else {
            setAlrtMsg(unblockedConsumer.message);
            setErrorAlrtOpen(true);
            DeleteModalClose();
          }
        }
        //handles delete condition
        else if (actionType.type === 2) {
          setLoaderData(true);
          const deletedUser = await ManagementServices.deleteAdmin(actionId);
          if (deletedUser.status) {
            setLoaderData(false);
            setAlrtMsg(deletedUser.message);
            setSuccessAlrtOpen(true);
            getGlobalOperationsDetails();
            DeleteModalClose();
          } else {
            setAlrtMsg(deletedUser.message);
            setErrorAlrtOpen(true);
            DeleteModalClose();
          }
        } else if (actionType.type === 4) {
          setLoaderData(true);
          const approval = await ManagementServices.userActionApproval(payload);
          if (approval.status) {
            setLoaderData(false);
            setAlrtMsg(approval.message);
            setSuccessAlrtOpen(true);
            getGlobalOperationsDetails();
            DeleteModalClose();
          } else {
            setAlrtMsg(approval.message);
            setErrorAlrtOpen(true);
          }
        }
      }
    } catch (error) {
      console.log(`Error occured :${error}`);
    }
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const getGlobalOperationsDetails = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        searchValue: searchData,
        ...filterState,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      };
      const opDetails = await ManagementServices.getRoleWiseUserDetails(
        payload
      );
      if (opDetails.status === 1) {
        const {
          data: { currentPage, pageSize, totalPages, totalUsers, userDetails },
        } = opDetails;
        setPaginationData({
          ...paginationData,
          currentPage: currentPage,
          RecordsForPage: pageSize,
          totalRecords: totalUsers,
          pageCount: totalPages,
        });
        setGlblOperations(userDetails);
        setLoaderData(false);
        // setAlrtMsg(opDetails.message);
        // setSuccessAlrtOpen(true);
      } else {
        setAlrtMsg(opDetails.message);
        setErrorAlrtOpen(true);
        setLoaderData(false);
      }
    } catch (error) {
      console.error(`Error occured:${error}`);
      setLoaderData(false);
    }
  };

  const createTypography = (condition, text, classText) =>
    condition && <Typography className={classText}>{text}</Typography>;

  const TableHeader = [
    "S No",
    "User Id",
    "First Name",
    "Last Name",
    "Phone",
    "Email Id",
    "Date",
    "Last Modified Date",
    "Address",
    ...(adminrole === 4 || adminrole === 2 || adminrole === 3
      ? ["Status"]
      : []),
    "Actions",
  ];

  // const iconConfigurations = (data) => {
  //   let baseConfigurations;
  //   if ((adminrole === 4 || adminrole === 2 || adminrole === 3) ) {
  //     if (adminDetails.is_user_admin) {

  //       return baseConfigurations = [
  //         {
  //           condition: data.block_button_disable || data.unblock_button_disable || data.delete_button_disable || data.edit_button_disable || data.approval_status === 0,
  //           icon: ApproveIcon,
  //           alt: "Approve" ,
  //           onClick: () => actionsHandleOpen(data, "Approve",4 ,false)
  //         },
  //         {
  //           condition: data.block_button_disable || data.unblock_button_disable || data.delete_button_disable || data.edit_button_disable || data.approval_status===0,
  //           icon: CancelIcon,
  //           alt: "Cancel" ,
  //           onClick: () => actionsHandleOpen(data, "Cancel",4,true)
  //         },
  //         {
  //           condition: !data.block_button_disable && !data.unblock_button_disable && !data.delete_button_disable && !data.edit_button_disable && data.approval_status !== 0,
  //           icon: EditIcon,
  //           alt: "Edit",
  //           onClick: () => editModalHandleOpen(data.id),
  //         },
  //         {
  //           condition: !data.block_button_disable && !data.unblock_button_disable && !data.delete_button_disable && !data.edit_button_disable && data.approval_status !== 0 && data.block_status === 1,
  //           icon: UnBlockIcon,
  //           alt: "Unblock",
  //           onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
  //         },
  //         {
  //           condition: !data.block_button_disable && !data.unblock_button_disable && !data.delete_button_disable && !data.edit_button_disable && data.approval_status !== 0 && data.block_status === 0,
  //           icon: BlockIcon,
  //           alt: "Block",
  //           onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
  //         },

  //         {
  //           condition: !data.block_button_disable && !data.unblock_button_disable && !data.delete_button_disable && !data.edit_button_disable && data.approval_status !== 0,
  //           icon: DeleteIcon,
  //           alt: "Delete",
  //           onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
  //         },
  //         {
  //           condition: true,
  //           icon: ViewIcon,
  //           alt: "View",
  //           onClick: () => roleModalOpen(data.id),
  //         },
  //       ];
  //     }

  //    return baseConfigurations = [
  //       {
  //         condition: data.block_button_disable || data.unblock_button_disable || data.delete_button_disable || data.edit_button_disable || data.approval_status === 0,
  //         icon: ApproveIcon,
  //         alt: "Approve" ,
  //         onClick: () => actionsHandleOpen(data, "Approve",4 ,false)
  //       },
  //       {
  //         condition: data.block_button_disable || data.unblock_button_disable || data.delete_button_disable || data.edit_button_disable || data.approval_status===0,
  //         icon: CancelIcon,
  //         alt: "Cancel" ,
  //         onClick: () => actionsHandleOpen(data, "Cancel",4,true)
  //       },
  //       {
  //         condition: !data.block_button_disable && !data.unblock_button_disable && !data.delete_button_disable && !data.edit_button_disable && data.approval_status !== 0,
  //         text: "-",
  //         alt: "No Action",
  //       },
  //     ];

  //   } else {
  //     return [
  //       {
  //         condition: true,
  //         icon: EditIcon,
  //         alt: "Edit",
  //         onClick: () => editModalHandleOpen(data.id),
  //       },
  //       {
  //         condition: data.block_status === 1,
  //         icon: UnBlockIcon,
  //         alt: "Unblock",
  //         onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
  //       },
  //       {
  //         condition: data.block_status === 0,
  //         icon: BlockIcon,
  //         alt: "Block",
  //         onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
  //       },
  //       {
  //         condition: true,
  //         icon: DeleteIcon,
  //         alt: "Delete",
  //         onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
  //       },
  //     ];
  //   }
  // };
  const iconConfigurations = (data) => {
    if (adminrole === 4 || adminrole === 2 || adminrole === 3) {
      let baseConfigurations;
      if (adminDetails.is_user_admin) {
        return (baseConfigurations = [
          {
            condition:
              data.block_button_disable ||
              data.unblock_button_disable ||
              data.delete_button_disable ||
              data.edit_button_disable ||
              data.approval_status === 0,
            icon: ApproveIcon,
            alt: "Approve",
            onClick: () => actionsHandleOpen(data, "Approve", 4, false),
          },
          {
            condition:
              data.block_button_disable ||
              data.unblock_button_disable ||
              data.delete_button_disable ||
              data.edit_button_disable ||
              data.approval_status === 0,
            icon: CancelIcon,
            alt: "Cancel",
            onClick: () => actionsHandleOpen(data, "Cancel", 4, true),
          },
          {
            condition:
              !data.block_button_disable &&
              !data.unblock_button_disable &&
              !data.delete_button_disable &&
              !data.edit_button_disable &&
              data.approval_status !== 0,
            icon: EditIcon,
            alt: "Edit",
            onClick: () => editModalHandleOpen(data.id),
          },
          {
            condition:
              !data.block_button_disable &&
              !data.unblock_button_disable &&
              !data.delete_button_disable &&
              !data.edit_button_disable &&
              data.approval_status !== 0 &&
              data.block_status === 1,
            icon: UnBlockIcon,
            alt: "Unblock",
            onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
          },
          {
            condition:
              !data.block_button_disable &&
              !data.unblock_button_disable &&
              !data.delete_button_disable &&
              !data.edit_button_disable &&
              data.approval_status !== 0 &&
              data.block_status === 0,
            icon: BlockIcon,
            alt: "Block",
            onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
          },

          {
            condition:
              !data.block_button_disable &&
              !data.unblock_button_disable &&
              !data.delete_button_disable &&
              !data.edit_button_disable &&
              data.approval_status !== 0,
            icon: DeleteIcon,
            alt: "Delete",
            onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
          },
          {
            condition: true,
            icon: ViewIcon,
            alt: "View",
            onClick: () => roleModalOpen(data.id),
          },
        ]);
      }

      return (baseConfigurations = [
        {
          condition:
            data.block_button_disable ||
            data.unblock_button_disable ||
            data.delete_button_disable ||
            data.edit_button_disable ||
            data.approval_status === 0,
          icon: ApproveIcon,
          alt: "Approve",
          onClick: () => actionsHandleOpen(data, "Approve", 4, false),
        },
        {
          condition:
            data.block_button_disable ||
            data.unblock_button_disable ||
            data.delete_button_disable ||
            data.edit_button_disable ||
            data.approval_status === 0,
          icon: CancelIcon,
          alt: "Cancel",
          onClick: () => actionsHandleOpen(data, "Cancel", 4, true),
        },
        {
          condition:
            !data.block_button_disable &&
            !data.unblock_button_disable &&
            !data.delete_button_disable &&
            !data.edit_button_disable &&
            data.approval_status !== 0,
          icon: ViewIcon,
          alt: "View",
          onClick: () => roleModalOpen(data.id),
        },
      ]);
    } else {
      return [
        {
          condition: true,
          icon: EditIcon,
          alt: "Edit",
          onClick: () => editModalHandleOpen(data.id),
        },
        {
          condition: data.block_status === 1,
          icon: UnBlockIcon,
          alt: "Unblock",
          onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
        },
        {
          condition: data.block_status === 0,
          icon: BlockIcon,
          alt: "Block",
          onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
        },
        {
          condition: true,
          icon: DeleteIcon,
          alt: "Delete",
          onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
        },
      ];
    }
  };
  const TableHeaderRow = TableHeader.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = glblOperation.map((data, index) => (
    <TableRow key={data.id}>
      <TableCellComponent
        value={
          (paginationData.currentPage - 1) * paginationData.RecordsForPage +
          1 +
          index
        }
        indexColoumn={true}
      />
      <TableCellComponent value={data.user_id} />
      <TableCellComponent value={data.first_name} />
      <TableCellComponent value={data.last_name} />
      <TableCellComponent value={data.phone} />
      <TableCellComponent value={data.email} />
      <TableCellComponent value={ConvertDateString(data.created_at)} />
      <TableCellComponent
        value={data.updated_at ? ConvertDateString(data.updated_at) : "-"}
      />
      <TableCellComponent value={data.address} />
      {(adminrole === 4 || adminrole === 2 || adminrole === 3) && (
        <TableCellComponent
          value={
            createTypography(
              data.block_button_disable,
              "Block",
              "blockTextColor"
            ) ||
            createTypography(
              data.delete_button_disable,
              "Delete",
              "deleteTextColor"
            ) ||
            createTypography(
              data.unblock_button_disable,
              "Unblock",
              "unblockTextColor"
            ) ||
            createTypography(
              data.edit_button_disable,
              "Modify",
              "modifyTextColor"
            ) ||
            (data.approval_status === 0 && <Typography>New</Typography>) ||
            (data.approval_status === 2 && (
              <Typography>Rejected</Typography>
            )) || <Typography>-</Typography>
          }
        />
      )}
      {(data.block_button_disable ||
        data.delete_button_disable ||
        data.unblock_button_disable ||
        data.edit_button_disable ||
        data.approval_status === 0 ||
        data.approval_status === 2) &&
      adminrole === 1 ? (
        <TableCellComponent
          value={
            createTypography(
              data.block_button_disable,
              "Block approval pending",
              "blockTextColor"
            ) ||
            createTypography(
              data.delete_button_disable,
              "Delete approval pending",
              "deleteTextColor"
            ) ||
            createTypography(
              data.unblock_button_disable,
              "Unblock approval pending",
              "unblockTextColor"
            ) ||
            createTypography(
              data.edit_button_disable,
              "Modify approval pending",
              "modifyTextColor"
            ) ||
            (data.approval_status === 0 && (
              <Typography>New approval pending</Typography>
            )) ||
            (data.approval_status === 2 && (
              <Typography>Rejected</Typography>
            )) || <Typography>-</Typography>
          }
        />
      ) : (
        <IconTableCellComponent
          data={data}
          iconConfigurations={iconConfigurations}
        />
      )}
    </TableRow>
  ));

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  React.useEffect(() => {
    getGlobalOperationsDetails();
    GetCountries();
  }, [selectedCountry, selectedState, selectedCity]);

  React.useEffect(() => {
    getGlobalOperationsDetails();
    GetCountries();
  }, [pageConfig.min, searchData, filterState]);

  const fetchData = async () => {
    try {
      let adminDetails = await GetAdminDetails();
      setAdminrole(adminDetails.role_id);
    } catch (error) {
      console.error(`Error occurred while fetching admin details:${error}`);
    }
  };

  React.useEffect(() => {
    fetchData();
    settingAdmin();
  }, []);
  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={moduleHeadContainer}
          >
            <Grid item md={3}>
              <TitleComponent title={"Supervisor's Role"} />
            </Grid>
            <Grid item md={9}>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"end"}
                gap={1}
              >
                <Grid item md={2}>
                  <TableFilter filterChange={FilterChangingValue} />
                </Grid>
                <Grid item md={2.6}>
                  <SearchFieldComponent searchText={handleSearchData} />
                </Grid>
                <Grid md={1.8}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCountry !== null ? selectedCountry : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "Country" }}
                      onChange={handleChangeCountry}
                    >
                      <MenuItem value="">Select country</MenuItem>
                      {totalCountries}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid md={1.8}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedState !== null ? selectedState : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "State" }}
                      onChange={handleChangeState}
                    >
                      <MenuItem value="">Select State</MenuItem>
                      {totalStates}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid md={1.8}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCity !== null ? selectedCity : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "City" }}
                      onChange={handleChangeCity}
                    >
                      <MenuItem value="">Select city</MenuItem>
                      {totalCities}
                    </Select>
                  </FormControl>
                </Grid>
                {adminrole === 1 || adminDetails.is_user_admin ? (
                  <Grid item md={1.5}>
                    <ButtonComponent
                      onClick={addModalHandleOpen}
                      btnText={"ADD"}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={tableParentHolder}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <AddModalComponent
            openhandler={addModal}
            handleClose={addModalCloseModal}
            trigger={getGlobalOperationsDetails}
          />
          <EditModalComponent
            open={editModal}
            handleClose={editModalCloseModal}
            edit={editId}
          />
          <BlockOrDeleteModalComponent
            deleteopen={deleteModal}
            resType={handleRes}
            onClose={DeleteModalClose}
            title={`Do you want to ${actionType.title}?`}
          />
          <RoleDetailModal
            open={roleModal}
            handleClose={roleCloseModal}
            userId={roleuserId}
          />
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};

export default Supervisor;

import { Button, Grid, Typography } from "@mui/material";
import Lottie from "lottie-react";
import Cooking from "../../assets/jsonLoaders/cooking.json";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const NotFound = () => {
  const Navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/login" &&
        location.pathname !== "/informative" &&
        location.pathname !== "/deleteUser" &&
        location.pathname !== "/informativePdf" && (
          <Grid
            style={{
              width: "100vw",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Grid
              style={{
                width: "20%",
                height: "auto",
              }}
            >
              <Lottie animationData={Cooking} loop={true} autoplay={true} />
              <Typography variant="h3">404 Not Found</Typography>
              <Typography>Provided URL is not correct</Typography>
              <Grid sx={{ margin: "20px" }}>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={() => {
                    Navigate("/");
                  }}
                >
                  back to home
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
    </>
  );
};

import React, { useState } from "react";
import {
  HeaderStyle,
  profilestyle,
  notificationStyle,
  profileImgStyle,
  profileModal,
  modalHeading,
  profileEdit,
  contactSubmitButton,
} from "../../styles/common_styles";
import {
  Grid,
  Typography,
  Badge,
  Button,
  TextField,
  FormControl,
} from "@mui/material";
// import Login_image from "../../assets/images/Login_image.png";
import { GetAdminDetails, GetRoleDetails } from "../../utils/helpers";
import { NotificationPopover } from "../notification_modal";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { ManagementServices } from "../../services/management_services";
import { StorageClient } from "../../utils/helpers";
import { SuccessAlert, ErrorAlert } from "../alert";
import AvatarImg from "../../assets/images/avatar.svg";
// import MailIcon from "@mui/icons-material/Mail";
import { makeStyles } from "@mui/styles";
import { blockOrDeleteModalStyle } from "../../styles/common_styles";
import { ButtonComponent } from "../form_components/button";
// import io from "socket.io-client";
// import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  redDot: {
    backgroundColor: "red",
    width: "2px !important",
    height: 2,
    // borderRadius: "50%",
  },
}));

const Header = () => {
  const classes = useStyles();
  const [testadminDetails, setTestAdminDetails] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [phoneValidationError, setPhoneValidationError] = useState(false);
  const [handleEdit, setHandleEdit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [openProfileModal, setOpenProfileModal] = React.useState(false);
  const [serverCount, setServerCount] = React.useState(0);
  const [userId, setUserId] = React.useState("");
  const navigate = useNavigate();

  const [adminDetails, setAdminDetails] = React.useState({
    name: "",
  });

  const [editTestDetails, setEditTestDetails] = React.useState({
    firstName: "",
    lastName: "",
  });
  const [roleDetails, setRoleDetails] = React.useState({
    role_name: "",
    id: "",
  });
  // const navigate = useNavigate();

  const notifications = (data) => {
    setTotalCount(data);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const TotalNotifications = async () => {
    try {
      const Notifications = await ManagementServices.getNotificationCountFunc();
      if (Notifications?.status === 1) {
        setTicketsCount(Notifications?.data?.count[7].count);
      }
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  };

  const AdminDetails = async () => {
    try {
      const AdminDetails = await GetAdminDetails();
      setUserId(AdminDetails.id);
      setTestAdminDetails(AdminDetails);
      setEditTestDetails({
        firstName: AdminDetails.first_name,
        lastName: AdminDetails.last_name,
      });
      const GetRoleDetailss = await GetRoleDetails();
      const name = AdminDetails.email.split(".")[0];
      const role_name = GetRoleDetailss.role_name;
      const id = GetRoleDetailss.role_id;
      setAdminDetails({ ...adminDetails, name });
      setRoleDetails({ ...roleDetails, role_name, id });
    } catch (error) {
      console.error(`Error occured :${error}`);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setHandleEdit(true);
    setOpenProfileModal(false);
  };

  const handleFieldChange = (e, fieldName) => {
    if (fieldName === "phone") {
      const value = e.target.value.replace(/\D/g, "");
      setTestAdminDetails({ ...testadminDetails, [fieldName]: value });
      return;
    }
    setTestAdminDetails({ ...testadminDetails, [fieldName]: e.target.value });
  };

  const handleNo = () => {
    setOpenProfileModal(false);
  };

  const handleYes = async () => {
    setOpenProfileModal(false);
    setHandleEdit(true);
    const payload = {
      userId: testadminDetails.id,
      firstName: testadminDetails.first_name,
      lastName: testadminDetails.last_name,
      phone: testadminDetails.phone,
      address: testadminDetails.address,
    };
    let updateProfile;
    updateProfile = await ManagementServices.updateUserProfile(payload);
    if (updateProfile.status === 1) {
      StorageClient.setItem("accessToken", updateProfile.data.accessToken);
      StorageClient.setItem("refreshToken", updateProfile.data.refreshToken);
      const AdminDetails = await GetAdminDetails();
      setTestAdminDetails(AdminDetails);
      setAlrtMsg(updateProfile.message);
      setSuccessAlrtOpen(true);
      setOpen(false);
      setHandleEdit(true);
    } else if (updateProfile.status === 2) {
      StorageClient.clear();
      navigate("/login");
    } else {
      setAlrtMsg(updateProfile.message);
      setErrorAlrtOpen(true);
    }
  };

  const showAlertModal = () => {
    return (
      <>
        <Modal
          open={openProfileModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={blockOrDeleteModalStyle}>
            <Typography textAlign={"center"}>
              This Action may lead to block your account
            </Typography>
            <Typography textAlign={"center"}>
              Do you want to continue?
            </Typography>
            <Grid container justifyContent={"space-around"} sx={{ mt: 3 }}>
              <Grid item>
                <ButtonComponent btnText={"No"} onClick={() => handleNo()} />
              </Grid>
              <Grid item>
                <ButtonComponent btnText={"Yes"} onClick={() => handleYes()} />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    );
  };

  const handleSave = async () => {
    if (
      (editTestDetails.firstName !== testadminDetails.first_name ||
        editTestDetails.lastName !== testadminDetails.last_name) &&
      roleDetails.role_name !== "Global Ops, UserAdmin"
    ) {
      setOpenProfileModal(true);
    } else {
      const payload = {
        userId: testadminDetails.id,
        firstName: testadminDetails.first_name,
        lastName: testadminDetails.last_name,
        phone: testadminDetails.phone,
        address: testadminDetails.address,
      };
      let updateProfile;
      updateProfile = await ManagementServices.updateUserProfile(payload);
      if (updateProfile.status === 1) {
        StorageClient.setItem("accessToken", updateProfile.data.accessToken);
        StorageClient.setItem("refreshToken", updateProfile.data.refreshToken);
        const AdminDetails = await GetAdminDetails();
        setTestAdminDetails(AdminDetails);
        setAlrtMsg(updateProfile.message);
        setSuccessAlrtOpen(true);
        setOpen(false);
        setHandleEdit(true);
      } else if (updateProfile.status === 2) {
        StorageClient.clear();
        navigate("/login");
      } else {
        setAlrtMsg(updateProfile.message);
        setErrorAlrtOpen(true);
      }
    }
    // const payload = {
    //   userId: testadminDetails.id,
    //   firstName: testadminDetails.first_name,
    //   lastName: testadminDetails.last_name,
    //   phone: testadminDetails.phone,
    //   address: testadminDetails.address,
    // };
    // let updateProfile;
    //   updateProfile = await ManagementServices.updateUserProfile(payload);
    // if (updateProfile.status === 1) {
    //   StorageClient.setItem("accessToken", updateProfile.data.accessToken);
    //   StorageClient.setItem("refreshToken", updateProfile.data.refreshToken);
    //   const AdminDetails = await GetAdminDetails();
    //   setTestAdminDetails(AdminDetails);
    //   setAlrtMsg(updateProfile.message);
    //   setSuccessAlrtOpen(true);
    //   setOpen(false);
    //   setHandleEdit(true);
    // } else if (updateProfile.status === 2) {
    //   StorageClient.clear();
    //   navigate("/login");
    // } else {
    //   setAlrtMsg(updateProfile.message);
    //   setErrorAlrtOpen(true);
    // }
  };

  // const handleRedirection = () => {
  //   navigate("/tickets");
  // };

  // const ENDPOINT = `http://10.1.10.101:5000/sseEvents/${userId}`;

  // React.useEffect(() => {
  //   const eventSource = new EventSource(`${ENDPOINT}`);

  //   eventSource.onmessage = function (event) {
  //     const parsedData = JSON.parse(event.data);
  //     console.log("Received event:", parsedData); // Add this line
  //     // setEventData(parsedData);
  //   };

  //   return () => {
  //     eventSource.close();
  //   };
  // }, [userId]);

  React.useEffect(() => {
    AdminDetails();
    TotalNotifications();
  }, [totalCount]);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await TotalNotifications();
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  //   const intervalId = setInterval(fetchData, 5000);
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  console.log(testadminDetails, "testadminDetails");

  return (
    <>
      <Grid sx={HeaderStyle}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"100%"}
          px={3}
        >
          <Grid>
            <Typography variant="h6">{roleDetails.role_name}</Typography>
            <Typography>
              {testadminDetails?.first_name ? testadminDetails?.first_name : ""}{" "}
              {testadminDetails?.last_name ? testadminDetails?.last_name : ""}
            </Typography>
          </Grid>
          <Grid sx={profilestyle}>
            <Grid sx={notificationStyle}>
              <Badge
                color="secondary"
                badgeContent={totalCount}
                onClick={handleOpenPopover}
              >
                <NotificationPopover
                  notifications={notifications}
                  anchorEl={anchorEl}
                  handleClose={handleClosePopover}
                />
              </Badge>
            </Grid>
            <Grid sx={profileImgStyle} onClick={handleOpen}>
              <img
                src={AvatarImg}
                style={{ height: "50px", width: "50px", borderRadius: "100px" }}
                alt="Login_image"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={profileModal}>
          <Grid item>
            <Typography sx={modalHeading}>Profile</Typography>
            {handleEdit && (
              <EditIcon sx={profileEdit} onClick={() => setHandleEdit(false)} />
            )}
          </Grid>
          <Grid container pt={3}>
            <Grid item md={6} p={1}>
              <Grid p={2}>
                <FormControl fullWidth>
                  <Grid>First name</Grid>
                  <TextField
                    disabled={handleEdit}
                    onChange={(e) => handleFieldChange(e, "first_name")}
                    fullWidth
                    size="small"
                    defaultValue={testadminDetails?.first_name}
                    inputProps={{
                      placeholder: "Name",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid p={2}>
                <FormControl fullWidth>
                  <Grid>Phone number</Grid>
                  <TextField
                    disabled={handleEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (/^\d*$/.test(value)) {
                        handleFieldChange(e, "phone");
                        setPhoneValidationError(false);
                      } else {
                        setPhoneValidationError(true);
                      }
                    }}
                    fullWidth
                    size="small"
                    type="number"
                    margin="normal"
                    defaultValue={testadminDetails?.phone}
                    inputProps={{
                      placeholder: "Phone",
                    }}
                    helperText={
                      phoneValidationError
                        ? "Please enter a valid phone number"
                        : ""
                    }
                    error={phoneValidationError}
                  />
                </FormControl>
              </Grid>
              <Grid p={2}>
                <FormControl fullWidth>
                  <Grid>Address</Grid>
                  <TextField
                    disabled={handleEdit}
                    onChange={(e) => handleFieldChange(e, "address")}
                    fullWidth
                    size="small"
                    defaultValue={testadminDetails?.address}
                    inputProps={{
                      placeholder: "Name",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={6} p={1}>
              <Grid p={2}>
                <FormControl fullWidth>
                  <Grid>Last name</Grid>
                  <TextField
                    disabled={handleEdit}
                    onChange={(e) => handleFieldChange(e, "last_name")}
                    fullWidth
                    size="small"
                    defaultValue={testadminDetails?.last_name}
                    inputProps={{
                      placeholder: "Name",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid p={2} mt={0.9}>
                <FormControl fullWidth>
                  <Grid>Email</Grid>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    value={testadminDetails?.email}
                    inputProps={{
                      placeholder: "Name",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid p={2} mt={2.3}>
                <FormControl fullWidth>
                  <Grid>Role</Grid>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    value={testadminDetails?.role_name}
                    inputProps={{
                      placeholder: "Name",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item textAlign={"center"} pt={4}>
            {!handleEdit && (
              <Button sx={contactSubmitButton} onClick={(e) => handleSave(e)}>
                SAVE
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      {showAlertModal()}
    </>
  );
};

export default Header;

import * as React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import { ButtonComponent } from "../../components/form_components/button";
import { TableComponent } from "../../components/table_component";
import { BlockOrDeleteModalComponent } from "../../components/modal_component";
import {
  tableHeadCell,
  moduleHeadContainer,
  tableParentHolder,
} from "../../styles/common_styles";
import { HFPagination } from "../../components/pagination";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import {
  // TableCellComponent,
  IconTableCellComponent,
} from "../../components/table_cell_component";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import { CommonServices } from "../../services/common_services";
import { Loader } from "../../components/loader_component";
import { GetAdminDetails } from "../../utils/helpers";
import EditRevenueModal from "../../components/EditRevenueModal";
import AddRevenueModal from "../../components/addRevenueModal";

const RevenueDetails = () => {
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [addModal, setAddModal] = React.useState(false);
  const addModalHandleOpen = () => setAddModal(true);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [revenueDetails, setRevenueDetails] = React.useState([]);
  const [actionId, setActionId] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [loaderData, setLoaderData] = React.useState(true);
  const [adminrole, setAdminrole] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const DeleteModalClose = () => setDeleteModal(false);
  const addModalCloseModal = () => {
    setAddModal(false);
    setIsEditMode(false);
    setEditId("");
    setAddModal(false);
    getRevenueDetailsForChef();
  };

  const GetCountries = async () => {
    try {
      const payload = {
        adminrole,
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };
  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(null);
    setSelectedCity(null);
    // getCommercialKitchenDetails();
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(null);
    GetCities(3, event.target.value, selectedCountry);
  };

  const editModalHandleOpen = (id) => {
    setEditModal(true);
    setEditId(id);
  };
  const editModalCloseModal = () => {
    setEditId("");
    setEditModal(false);
    getRevenueDetailsForChef();
  };

  const [actionType, setActiontype] = React.useState({
    title: "",
    type: null,
  });
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });
  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const blockOrDeleteHandleOpen = (id, title, type) => {
    setActionId(id);
    setDeleteModal(true);
    setActiontype({ ...actionType, title, type });
  };

  const handleRes = async (data) => {
    try {
      const payload = {
        revenueId: actionId,
      };
      //handles No condition
      if (data === 1) {
        DeleteModalClose();
      } else {
        const deletedRevenueKitchen =
          await ManagementServices.deleteRevenueLimitItem(payload);
        if (deletedRevenueKitchen.status) {
          setLoaderData(false);
          setAlrtMsg(deletedRevenueKitchen.message);
          setSuccessAlrtOpen(true);
          getRevenueDetailsForChef();
          DeleteModalClose();
        } else {
          setAlrtMsg(deletedRevenueKitchen.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.log(`Error occured :${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const getRevenueDetailsForChef = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      };
      const opDetails = await ManagementServices.revenueListForChef(payload);
      console.log(opDetails);
      if (opDetails.status === 1) {
        const {
          data: {
            currentPage,
            pageSize,
            totalPages,
            totalItems,
            chefRevenueLimitData,
          },
        } = opDetails;
        setPaginationData({
          ...paginationData,
          currentPage: currentPage,
          RecordsForPage: pageSize,
          totalRecords: totalItems,
          pageCount: totalPages,
        });
        setRevenueDetails(chefRevenueLimitData);
        setLoaderData(false);
        // setAlrtMsg(opDetails.message);
        // setSuccessAlrtOpen(true);
      } else {
        setAlrtMsg(opDetails.message);
        setErrorAlrtOpen(true);
        setLoaderData(false);
      }
    } catch (error) {
      console.error(`Error occured:${error}`);
      setLoaderData(false);
    }
  };

  const TableHeader = [
    "S No",
    "Country",
    "State",
    "Revenue",
    "Duration(Years)",
    "Actions",
  ];

  const iconConfigurations = (data) => {
    return [
      {
        condition: true,
        icon: EditIcon,
        alt: "Edit",
        onClick: () => editModalHandleOpen(data.id),
      },
      {
        condition: true,
        icon: DeleteIcon,
        alt: "Delete",
        onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
      },
    ];
  };

  const TableHeaderRow = TableHeader.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = revenueDetails.map((data, index) => (
    <TableRow key={data.id}>
      <TableCell sx={{ ml: 10, textAlign: "center" }}>
        {(paginationData.currentPage - 1) * paginationData.RecordsForPage +
          1 +
          index}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {data.country_name || "-"}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {data.state_name || "-"}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {data.revenue_amount || "-"}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>{data.duration || "-"}</TableCell>
      <IconTableCellComponent
        data={data}
        iconConfigurations={iconConfigurations}
      />
    </TableRow>
  ));

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const fetchData = async () => {
    try {
      let adminDetails = await GetAdminDetails();
      setAdminrole(adminDetails.role_id);
    } catch (error) {
      console.error(`Error occurred while fetching admin details:${error}`);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    getRevenueDetailsForChef();
    GetCountries();
  }, [selectedCountry, selectedState, selectedCity, pageConfig.min]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={moduleHeadContainer}
          >
            <Grid item md={3}>
              <TitleComponent title={"Chef Revenue"} />
            </Grid>
            <Grid item md={9}>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"end"}
                gap={1}
              >
                <Grid md={1.8}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCountry !== null ? selectedCountry : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "Country" }}
                      onChange={handleChangeCountry}
                    >
                      <MenuItem value="">Select country</MenuItem>
                      {totalCountries}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid md={1.8}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedState !== null ? selectedState : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "State" }}
                      onChange={handleChangeState}
                    >
                      <MenuItem value="">Select State</MenuItem>
                      {totalStates}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={1.5}>
                  <ButtonComponent
                    onClick={addModalHandleOpen}
                    btnText={"ADD"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={tableParentHolder}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <AddRevenueModal
            openhandler={addModal}
            handleClose={addModalCloseModal}
            trigger={getRevenueDetailsForChef}
            type={isEditMode}
            addType={1}
          />
          <EditRevenueModal
            open={editModal}
            handleClose={editModalCloseModal}
            edit={editId}
            trigger={getRevenueDetailsForChef}
            editList={revenueDetails}
            addType={1}
          />
          <BlockOrDeleteModalComponent
            deleteopen={deleteModal}
            resType={handleRes}
            onClose={DeleteModalClose}
            title={`Do you want to ${actionType.title}?`}
          />
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};

export default RevenueDetails;

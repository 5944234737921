import * as React from "react";
import {
  Box,
  Grid,
  TextField,
  TableCell,
  TableRow,
  IconButton,
  Modal,
} from "@mui/material";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import "../../styles/common_styles.css";
import { TableComponent } from "../../components/table_component";
import { tableHeadCell, tableParentHolderr } from "../../styles/common_styles";
// import { TableCellComponent } from "../../components/table_cell_component";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { HFPagination } from "../../components/pagination";
// import { Loader } from "../../components/loader_component";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Loader } from "../../components/loader_component";
import { SpanStyle } from "../../styles/common_styles";
import { Troubleshoot } from "@mui/icons-material";
export const ConsumerReviews = (personId) => {
  // const location = useLocation();
  // const moduleType = location.state?.module;
  // const userId = location.state?.userId;
  const moduleType = personId?.fromModule;
  const userId = personId?.personId;
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [reviews, setReviews] = React.useState([]);
  const [searchData, setSearchData] = React.useState("");
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [loaderData, setLoaderData] = React.useState(true);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [reviewMessage, setReviewMessage] = React.useState({});
  const [loadingData, setLoadingData] = React.useState(false);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const openReviewModal = async (data) => {
    setReviewModal(true);
    setReviewMessage(data);
  };

  const closeReviewModal = () => {
    setReviewModal(false);
    setReviewMessage("");
  };

  const messageViewModal = () => {
    return (
      <Modal
        open={reviewModal}
        onClose={closeReviewModal}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 750,
            height: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Box
            sx={{
              height: "65vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
            }}
          >
            {loadingData ? (
              <Loader />
            ) : (
              <>
                <Grid sx={{ textAlign: "center", fontWeight: "bold" }}>
                  Review Detail Message
                </Grid>
                <Grid
                  container
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item md={6} sx={SpanStyle}>
                    Review Message
                  </Grid>
                  <Grid item md={6}>
                    {/* : {viewTicketDetails?.consumer_ticket?.first_name} */}:{" "}
                    {reviewMessage}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const tableHeaders = [
    "S No",
    "Order Id",
    "Date",
    moduleType === 1 ? "ChefName" : "ConsumerName",
    "Review",
  ];
  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const chefAndConsumerreviews = async () => {
    try {
      const type = moduleType === 1 ? 2 : 1;
      const payload = {
        userId: userId,
        min: pageConfig.min,
        max: pageConfig.max,
        fromdate: startDate,
        todate: endDate,
        search: searchData,
        type: type,
      };
      const reviews = await ManagementServices.reviewDetails(payload);
      if (reviews.status === 1) {
        setReviews(reviews.data.reviews);
        setPaginationData({
          ...paginationData,
          currentPage: reviews.data.currentPage,
          RecordsForPage: reviews.data.pageSize,
          totalRecords: reviews.data.totalReviews,
          pageCount: reviews.data.totalPages,
        });
        setLoaderData(false);
      } else {
        setErrorAlrtOpen(true);
      }
      // setAlrtMsg(reviews.message);
      // setSuccessAlrtOpen(reviews.status === 1);
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  };

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const TableBody = reviews?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCell sx={{ ml: 10, textAlign: "center" }}>
          {(paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>{data.order_id}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{data.created_at}</TableCell>
        {moduleType === 1 ? (
          <TableCell sx={{ textAlign: "center" }}>{data.chef_name}</TableCell>
        ) : (
          <TableCell sx={{ textAlign: "center" }}>
            {data.consumer_name}
          </TableCell>
        )}
        {/* <TableCell sx={{ textAlign: "center" }}>{data.review}</TableCell> */}
        <TableCell sx={{ textAlign: "center" }}>
          <IconButton
            sx={{ color: "black", textAlign: "center" }}
            onClick={() => openReviewModal(data.review)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  React.useEffect(() => {
    chefAndConsumerreviews();
  }, [pageConfig.min, searchData, startDate]);

  return (
    <Box>
      <Grid
        container
        justifyContent={"end"}
        alignItems={"end"}
        spacing={2}
        mb={2}
      >
        <Grid item md={3}>
          <SearchFieldComponent searchText={handleSearchData} />
        </Grid>
        <Grid item md={1.8}>
          <TextField
            type="date"
            size="small"
            InputProps={{
              placeholder: "startDate",
            }}
            onChange={(e) => {
              setStartDate(e.target.value);
              chefAndConsumerreviews();
            }}
          />
        </Grid>
        <Grid sx={{ mb: 1.5, fontWeight: "bold", ml: 0.5 }}>To</Grid>
        <Grid item md={1.5}>
          <TextField
            type="date"
            size="small"
            InputProps={{
              placeholder: "endDate",
            }}
            onChange={(e) => {
              setEndDate(e.target.value);
              chefAndConsumerreviews();
            }}
          />
        </Grid>
      </Grid>
      <Grid sx={tableParentHolderr}>
        {loaderData ? (
          <Loader />
        ) : (
          <>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </>
        )}
      </Grid>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      {messageViewModal()}
    </Box>
  );
};

import { TableCell, Typography, Tooltip, IconButton } from "@mui/material";
import { tableCellStyles, firstTableCellStyles } from "../styles/common_styles";

export const TableCellComponent = ({ value, indexColoumn, onClick }) => {
  return (
    <>
      <TableCell onClick={onClick} style={{ cursor: "pointer" }}>
        {indexColoumn === true ? (
          <Tooltip title={value}>
            <Typography sx={firstTableCellStyles}>{value}</Typography>
          </Tooltip>
        ) : (
          <Tooltip title={value}>
            <Typography sx={tableCellStyles}>{value}</Typography>
          </Tooltip>
        )}
      </TableCell>
    </>
  );
};

export const IconTableCellComponent = ({ data, iconConfigurations }) => {
  return (
    <>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        {iconConfigurations(data)?.map(
          (iconConfig, iconIndex) =>
            iconConfig.condition && (
              <Tooltip title={iconConfig.alt} key={iconIndex}>
                {iconConfig.icon ? (
                  <IconButton
                    sx={iconConfig.styles}
                    onClick={() => iconConfig.onClick(data.id)}
                  >
                    <img src={iconConfig.icon} alt={iconConfig.alt} />
                  </IconButton>
                ) : (
                  <Typography>{iconConfig.text}</Typography>
                )}
              </Tooltip>
            )
        )}
      </TableCell>
    </>
  );
};
